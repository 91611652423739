import { AxiosInstance } from 'axios'
import { LoadCategoriesByParent } from '../../domain/use-case/loadCategoriesByParent'

export class ApiLoadCategoriesByParent implements LoadCategoriesByParent {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadCategoriesByParent.Params): Promise<LoadCategoriesByParent.Result> {
    const response = await this.api.get(`/wp-json/plb2024/v1/category/${params.slug}/categories`)
    switch (response.status) {
      case 200:
        return response.data
      case 204:
        throw new Error('Sem conteudo')
      default:
        throw new Error('Erro interno do servidor')
    }
  }
}
