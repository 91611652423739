import { AxiosInstance } from 'axios'
import { LoadProductsByCartegory } from '../../domain/use-case/LoadProductsByCartegory'

export class ApiLoadProductsByCartegory implements LoadProductsByCartegory {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadProductsByCartegory.Params): Promise<LoadProductsByCartegory.Result> {
    const response = await this.api.get(`/wp-json/plb2024/v1/products/${params.slug}`)
    switch (response.status) {
      case 200:
        return response.data
      case 204:
        throw new Error('Sem conteudo')
      default:
        throw new Error('Erro interno do servidor')
    }
  }
}
