import { AxiosInstance } from 'axios'
import { LoadPageBySlug } from '../../domain/use-case/LoadPageBySlug'

export class ApiLoadPageBySlug implements LoadPageBySlug<any[]> {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (slug: string): Promise<LoadPageBySlug.Result<any[]>> {
    const response = await this.api.get(`/wp-json/plb2024/v1/page/${slug}`)
    switch (response.status) {
      case 200:
        return response.data
      case 204:
        throw new Error('Sem conteudo')
      default:
        throw new Error('Erro interno do servidor')
    }
  }
}
