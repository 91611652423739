import { ReactElement, useEffect, useState } from 'react'
import Gallery from '../../components/Gallery'
import UnderlinedTitle from '../../components/UnderlinedTitle'
import { PageState } from '../../data/enums/PageState'
import { LoadPageBySlug } from '../../domain/use-case/LoadPageBySlug'
import Timeline from './Timeline'
import gift from './assets/gift.gif'
import mission from './assets/mission.png'
import rocket from './assets/rocket.gif'
import * as S from './styled'

interface AboutProps {
  loadPageBySlug: LoadPageBySlug<any>
}

export default function About ({ loadPageBySlug }: AboutProps): ReactElement {
  const [isVisible, setIsVisible] = useState(false)
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [data, setData] = useState<LoadPageBySlug.Result<any>>({} as unknown as LoadPageBySlug.Result<any>)

  useEffect(() => {
    const handleAnimation = (): void => {
      const image = document.querySelector('.slide-in-image img')

      if (image === null) return

      const imagePosition = image.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (imagePosition < windowHeight / 0.8) {
        setIsVisible(true)
      }
    }

    if (window.innerWidth < 1124) {
      handleAnimation()
    } else {
      window.addEventListener('scroll', handleAnimation)
    }

    return () => window.removeEventListener('scroll', handleAnimation)
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const response = await loadPageBySlug.handle('nossa-empresa')
        setData(response)
        setState(PageState.READY)
      } catch (error) {
        const err = error as Error
        switch (err.name) {
          case 'Sem conteúdo':
            setState(PageState.NO_CONTENT)
        }
      }
    })()
  }, [])

  if (state === PageState.LOADING) return <>...</>
  if (state === PageState.NO_CONTENT) return <>Não temos conteúdo</>
  return <main>
    <S.BannerOne>
      <img src={data.page.thumbnail} alt="Polibras + Você construindo histórias de sucesso" />
    </S.BannerOne>

    <S.DescriptionContainer>
      <p dangerouslySetInnerHTML={{ __html: data.page.post_content }}></p>
    </S.DescriptionContainer>

    <S.MissionContainer>
      <figure className="slide-in-image">
        <img src={mission} alt="" className={`${isVisible ? 'visible' : ''}`} />
      </figure>

      <div dangerouslySetInnerHTML={{ __html: data.fields.missao.texto }}></div>
    </S.MissionContainer>

    <Timeline />

    <S.CauseAndMissionContainer>
      <S.CauseContainer>
        <figure>
          <img src={gift} alt="" />
        </figure>

        <h2>{data.fields.grupo_causamissao.nossa_causa.titulo}</h2>

        <p dangerouslySetInnerHTML={{ __html: data.fields.grupo_causamissao.nossa_causa.conteudo }}></p>
      </S.CauseContainer>

      <S.MissionDescriptionContainer>
        <figure>
          <img src={rocket} alt="" />
        </figure>

        <h2>{data.fields.grupo_causamissao.missao.titulo}</h2>

        <p dangerouslySetInnerHTML={{ __html: data.fields.grupo_causamissao.missao.conteudo }}></p>
      </S.MissionDescriptionContainer>
    </S.CauseAndMissionContainer>

    <S.PurposeContainer>
      <h1>{data.fields.nosso_proposito.titulo}</h1>

      <p dangerouslySetInnerHTML={{ __html: data.fields.nosso_proposito.conteudo }}></p>
    </S.PurposeContainer>

    <S.CertificationsContainer>
      <UnderlinedTitle>Certificações</UnderlinedTitle>

      <S.LogosContainer>
        {data.fields.certificacoes.map((foto: any, index: number) => <figure key={index}>
          <img src={foto.Imagem} alt="" />
        </figure>)}
      </S.LogosContainer>
    </S.CertificationsContainer>

    <S.StructureContainer>
      <UnderlinedTitle>Nossa Estrutura</UnderlinedTitle>

      <S.StructureContent>
        <div>
          <p><strong>Sede Administrativa</strong></p>
          <p>{data.fields.nossa_estrutura.sede_administrativa}</p>
        </div>

        <hr />

        <div>
          <p><strong>Fábrica: {data.fields.nossa_estrutura.fabrica.localidade}</strong></p>
          <p>Área Construída: {data.fields.nossa_estrutura.fabrica.area_construida}</p>
        </div>
      </S.StructureContent>
    </S.StructureContainer>

    <Gallery images={data.fields.fotos.map((foto: any) => foto.Imagem.url)} title="Unidade Fabril POLIBRAS – CAMBUÍ / MG" />
  </main>
}
