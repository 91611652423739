import { ReactElement } from 'react'
import PalletProps from '../props'
import * as S from './styled'

export default function Intro ({ data }: PalletProps): ReactElement {
  return <S.TransportContainer>
  <S.TransportContent>
    <h1>{data.fields.hero.titulo}</h1>

    <div dangerouslySetInnerHTML={{ __html: data.fields.hero.conteudo }}></div>
  </S.TransportContent>

  <figure>
    <img src={data.fields.hero.imagem} alt="" />
  </figure>
</S.TransportContainer>
}
