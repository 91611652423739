import { ReactElement, useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { PageState } from '../../data/enums/PageState'
import { LoadCategories } from '../../domain/use-case/LoadCategories'
import { LoadPageBySlug } from '../../domain/use-case/LoadPageBySlug'
import { LoadProductsByCartegory } from '../../domain/use-case/LoadProductsByCartegory'
import { LoadCategoriesByParent } from '../../domain/use-case/loadCategoriesByParent'
import Categories from './Categories'
import Releases from './Releases'
import arrowBack from './assets/arrowBack.png'
import arrowForward from './assets/arrowForward.png'
import indicator from './assets/indicator.png'
import indicatorSelected from './assets/indicatorSelected.png'
import * as S from './styled'

interface PaperProps {
  loadPageBySlug: LoadPageBySlug<any>
  loadCategories: LoadCategories
  loadCategoriesByParent: LoadCategoriesByParent
  loadProductsByCartegory: LoadProductsByCartegory
}

export default function Paper ({ loadPageBySlug, loadCategories, loadCategoriesByParent, loadProductsByCartegory }: PaperProps): ReactElement {
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [data, setData] = useState<LoadPageBySlug.Result<any>>({} as unknown as LoadPageBySlug.Result<any>)

  useEffect(() => {
    (async () => {
      try {
        const response = await loadPageBySlug.handle('papelaria')
        setData(response)
        setState(PageState.READY)
      } catch (error) {
        const err = error as Error
        switch (err.name) {
          case 'Sem conteúdo':
            setState(PageState.NO_CONTENT)
        }
      }
    })()
  }, [])

  const customRenderItem = (item: any, props: any): ReactElement => <item.type {...item.props} {...props} />

  if (state === PageState.LOADING) return <>...</>
  if (state === PageState.NO_CONTENT) return <>Não temos conteúdo</>

  return <S.Container>
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      interval={5000}
      renderArrowNext={(clickHandler) => <S.ArrowForward onClick={clickHandler}>
        <img src={arrowForward} alt="Próximo" />
      </S.ArrowForward>}
      renderArrowPrev={(clickHandler) => <S.ArrowBack onClick={clickHandler}>
        <img src={arrowBack} alt="Voltar" />
      </S.ArrowBack>}
      renderIndicator={(clickHandler, isSelected) => <S.Indicator onClick={clickHandler}>
        <img src={isSelected ? indicatorSelected : indicator} alt="" />
      </S.Indicator>}
      renderItem={customRenderItem}
      showStatus={false}
      showThumbs={false}
    >
      {data.fields.carousel.map(({ banner }: { banner: string }) => <figure key={banner}>
        <img src={banner} />
      </figure>
      )}
    </Carousel>

    <S.DescriptionContainer dangerouslySetInnerHTML={{ __html: data.page.post_content }}>
    </S.DescriptionContainer>

    <S.ReleasesContainer>
      <S.UnderlinedTitle>
        Lançamentos
      </S.UnderlinedTitle>

      <Releases loadCategoriesByParent={loadCategoriesByParent} loadProductsByCartegory={loadProductsByCartegory} />
    </S.ReleasesContainer>

    <S.LinesContainer>
      <S.UnderlinedTitle>
        Conheça todas as nossas linhas
      </S.UnderlinedTitle>

      <Categories loadCategories={loadCategories} />

      <S.DownloadButton download href={data.fields.catalogo} target="_self">
        <button>Baixe nosso Catálogo</button>
      </S.DownloadButton>
    </S.LinesContainer>
  </S.Container>
}
