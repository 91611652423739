import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import ContactItemProps from './props'
import * as S from './styled'

export default function ContactListItem ({ options, title }: ContactItemProps): ReactElement {
  return <S.Container>
    <p><strong>{title}</strong></p>

    {options.map((option) => option.includes('@')
      ? <Link key={option} to={`mailto:${option}`}>{option}</Link>
      : <p key={option}>{option}</p>)
    }
  </S.Container>
}
