import styled from 'styled-components'

export const TransportContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 100px 28px 32px;
  max-width: 1366px;

  figure {
    max-width: 36%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    figure {
      max-width: 50%;
    }
  }

  @media (max-width: 764px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`

export const TransportContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 42%;
  width: 100%;

  h1 {
    font-size: 30pt;
    font-weight: 800;
  }

  h2 {
    color: var(--primary);
    font-size: 13.5pt;
    font-weight: 700;
  }

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    line-height: 18pt;

    strong {
      font-weight: 700;
    }
  }

  @media (max-width: 1024px) {
    max-width: 50%;
  }

  @media (max-width: 764px) {
    max-width: 450px;

    h1 {
      text-align: center;
    }

    h2, p {
      font-size: 12pt;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 21pt;
    }
  }
`
