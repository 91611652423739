import { ReactElement } from 'react'
import IconItem from '../../IconItem'
import SeparatorSheetsProps from '../props'
import * as S from './styled'

export default function Advantages ({ data }: SeparatorSheetsProps): ReactElement {
  return <S.Container>
    <S.UnderlinedTitle>
      Principais vantagens no uso de <br/>Folha Separadora de Camadas Plásticas Polibras:
    </S.UnderlinedTitle>

    <S.Content>
      {data.fields.vantagens.items.map((item: { imagem: string, texto: string }) => <IconItem key={item.texto} icon={item.imagem} text={item.texto} />)}
    </S.Content>
  </S.Container>
}
