import { ReactElement } from 'react'
import ItemProps from './props'
import * as S from './styled'

export default function IconItem ({ className, icon, text }: ItemProps): ReactElement {
  return <S.Container className={className}>
    <S.Content>
      <S.IconContainer className="icon-container">
        <img src={icon} alt="" />
      </S.IconContainer>

      <p dangerouslySetInnerHTML={{ __html: text }}></p>
    </S.Content>
  </S.Container>
}
