import styled from 'styled-components'
import UT from '../../UnderlinedTitle'
import arrowBack from './assets/arrowBack.png'
import arrowForward from './assets/arrowForward.png'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: space-around;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  max-width: 1280px;
  padding: 64px 28px;
  place-content: center;

  @media (max-width: 764px) {
    gap: 16px;
    grid-template-columns: 1fr;
  }
`

export const CarouselContainer = styled.div`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
  padding: 28px 20px;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      aspect-ratio: 1;
      object-fit: contain;
      width: 100%;
    }
  }

  ul.slider.animated, li.slide {
    aspect-ratio: 1;
  }

  ul.thumbs.animated {
    li.thumb {
      border: none;
      cursor: zoom-in;
      overflow: hidden;
      &:hover {
        img {
          scale: 1.2;
          transition: all .6s linear;
        }
      }
    }
  }

  .carousel .control-arrow {
    margin-top: 0;
    opacity: .8;

    &.control-next {
      right: -16px;
    }

    &.control-prev {
      left: -16px;
    }

    &.control-prev::before,
    &.control-next::before {
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      content: '';
      height: 16px;
      width: 16px;
    }

    &.control-prev::before {
      background-image: url(${arrowBack});
    }

    &.control-next::before {
      background-image: url(${arrowForward});
    }
  }
`

const Arrow = styled.figure`
  align-items: center;
  bottom: 0;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 32px;
  position: absolute;
  top: 0;
  transition: all 0.5s linear;
  z-index: 1;

  img {
    max-width: 16px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, .1);
  }

  @media (max-width: 1024px) {
    max-width: 16px;
  }
`

export const ArrowBack = styled(Arrow)`
  left: 0;
`

export const ArrowForward = styled(Arrow)`
  right: 0;
`

export const ZoomInContainer = styled.figure`
  overflow: hidden;
  position: relative;

  a {
    img {
      transition: scale .6s linear;
    }

    span {
      background-color: rgba(0, 0, 0, .1);
      color: var(--white);
      left: 0;
      margin-inline: auto;
      opacity: 0;
      position: absolute;
      right: 0;
      transition: opacity 0.5s linear;
      z-index: 1;
    }
  }

  &:hover {
    img {
      scale: 1.1;
    }

    a span,
    button {
      opacity: 1;
    }
  }
`

export const MagnifierButton = styled.button`
  background-color: rgba(0, 0, 0, .1);
  border: none;
  border-radius: 50%;
  bottom: 16px;
  cursor: pointer;
  opacity: 0;
  padding: 6px;
  position: absolute;
  right: 40px;
  transition: opacity 0.5s linear;
  z-index: 2;

  svg {
    transition: scale .4s linear;
  }

  :active {
    outline: none;
  }

  &:hover {
    svg {
      scale: 1.05;
    }
  }
`

export const ModalZoom = styled.figure`
  line-height: 0;
  margin: 0;
  overflow: hidden;
  max-width: 1000px;
  width: 100%;

  img {
    transition: all .6s linear;
    width: 100%;
  }
`

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 460px;

  span {
    font-size: 9pt;
    font-weight: 700;
  }
`

export const UnderlinedTitle = styled(UT)`
  font-size: 18pt;
  line-height: 24pt;
  padding: 0;
  text-align: center;
  white-space: normal;
  width: fit-content;

  @media (max-width: 500px) {
    font-size: 13.5pt;
    line-height: 18pt;
    margin-inline: auto;
  }
`

export const TextContent = styled.div`
  margin-top: 32px;

  p {
    color: #888;
  }

  @media (max-width: 500px) {
    margin-top: 20px;
  }
`
