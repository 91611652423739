import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import NewsLinkProps from './props'

export default function NewsLink ({ children, newsId, title, ...rest }: NewsLinkProps): ReactElement {
  return <Link
    state={{ newsId }}
    to={`/blog/${title}`}
    {...rest}
  >
    {children}
  </Link>
}
