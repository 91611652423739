import { ReactElement, useCallback } from 'react'
import arrow from './assets/arrow_down.png'
import AccordionProps from './props'
import * as S from './styled'

export default function Accordion ({ children, className, index, isOpen = false, onOpen, title }: AccordionProps): ReactElement {
  const handleOpen = useCallback(() => {
    if (!isOpen) {
      onOpen(index)
      return
    }

    onOpen(undefined)
  }, [isOpen, onOpen, index])

  return <S.Container className={className}>
    <S.TitleContainer onClick={handleOpen}>
      <S.Title className="title">{title}</S.Title>

      <S.Icon $isOpen={isOpen}>
        <img src={arrow} alt="" />
      </S.Icon>
    </S.TitleContainer>

    {isOpen && (
      <S.Content className="content">
        {children}
      </S.Content>
    )}
  </S.Container>
}
