import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
`

export const TransportContainer = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 100px 28px 32px;
  max-width: 1366px;

  figure {
    max-width: 30%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    figure {
      max-width: 50%;
    }
  }

  @media (max-width: 764px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`

export const TransportContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 40%;
  width: 100%;

  h1 {
    font-size: 30pt;
    font-weight: 800;
  }

  h2 {
    color: var(--primary);
    font-size: 13.5pt;
    font-weight: 500;
  }

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    line-height: 18pt;

    strong {
      font-weight: 700;
    }
  }

  @media (max-width: 1420px) {
    h1 {
      font-size: 27pt;
    }
  }

  @media (max-width: 1024px) {
    max-width: 50%;
  }

  @media (max-width: 764px) {
    max-width: 450px;

    h1 {
      text-align: center;
    }

    h2, p {
      font-size: 12pt;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 21pt;
    }
  }
`

export const BenefitsSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1366px;
  padding: 32px 28px;
  width: 100%;
`

export const BenefitsContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  gap: 16px;
  width: 100%;

  > figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }

  @media (max-width: 764px) {
    flex-direction: column;

    > figure {
      max-width: 260px;
    }
  }
`

export const BenefitsAccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 656px;
  width: 100%;

  @media (max-width: 1024px) {
    p {
      font-size: 10.5pt;
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 9pt;
    }
  }
`

export const AttributesSection = styled.div`
  background-image: linear-gradient(to bottom, var(--white) 14%, var(--background) 14%, var(--background) 88%, var(--white) 88%);
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
  padding: 0 28px;
  position: relative;
  width: 100%;

  figure {
    max-width: 50%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 955px) {
    align-items: center;
    background-image: linear-gradient(to bottom, var(--white) 8%, var(--background) 8%);
    flex-direction: column;
    justify-content: center;
    padding: 0 28px 32px;

    figure {
      align-self: center;
      max-width: 480px;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 764px) {
    figure {
      max-width: 400px;
    }
  }
`

export const AttributesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1370px;
  padding-left: 64px;
  width: 50%;

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    max-width: 545px;
  }

  ul {
    list-style: none;

    li {
      align-items: center;
      color: var(--text-secondary);
      display: flex;
      gap: 8px;
      font-size: 13.5pt;
      margin-top: 8px;
      white-space: nowrap;

      img {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 1270px) {
    p {
      font-size: 12pt;
    }

    ul li {
      font-size: 12pt;

      img {
        width: 48px;
      }
    }
  }

  @media (max-width: 1170px) {
    p {
      font-size: 10.5pt;
    }

    ul {
      max-width: initial;

      li {
        font-size: 10.5pt;

        img {
          margin-right: 8px;
          width: 48px;
        }
      }
    }
  }

  @media (max-width: 954px) {
    padding: 0;
    width: initial;

    p {
      font-size: 12pt;
    }

    ul {
      margin-top: 0;
      max-width: initial;

      li {
        font-size: 12pt;
        margin-top: 16px;
        white-space: initial;

        img {
          width: 56px;
        }
      }
    }
  }

  @media (max-width: 764px) {
    p {
      font-size: 10.5pt;
    }

    ul {
      margin-top: 8px;

      li {
        font-size: 10.5pt;
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 460px) {
    p {
      font-size: 9pt;
    }

    ul {
      margin-top: 4px;

      li {
        font-size: 9pt;
        margin-top: 4px;

        img {
          width: 36px;
        }
      }
    }
  }
`

export const NylonContainer = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 32px 28px;
  max-width: 1280px;

  @media (max-width: 764px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`

export const NylonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > figure {
    align-self: center;
    max-width: 50%;

    img {
      width: 100%;
    }

    @media (min-width: 765px) {
      display: none;
    }
  }

  h1 {
    font-size: 30pt;
    font-weight: 800;
    max-width: 60%;
  }

  > div {
    align-items: center;
    display: flex;
    gap: 16px;
    width: 100%;

    > div {
      width: 40%;
    }

    p {
      color: var(--text-secondary);
      font-size: 13.5pt;
      line-height: 18pt;

      strong {
        font-weight: 700;
      }
    }

    figure {
      margin-inline: auto;
      max-width: fit-content;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 1420px) {
    h1 {
      font-size: 27pt;
    }
  }

  @media (max-width: 1024px) {
    > div {
      p {
        max-width: 50%;
      }

      figure {
        max-width: 40%;
      }
    }
  }

  @media (max-width: 764px) {
    h1 {
      max-width: initial;
      text-align: center;
    }

    > div {
      p {
        font-size: 12pt;
        max-width: initial;
      }

      figure {
        display: none;
      }
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 21pt;
    }

    > div p {
      font-size: 12pt;
    }
  }
`
