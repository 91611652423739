import { ReactElement } from 'react'
import UnderlinedTitle from '../../../components/UnderlinedTitle'
import hover87 from '../assets/1987.png'
import hover97 from '../assets/1997.png'
import hover02 from '../assets/2002.png'
import hover03 from '../assets/2003.png'
import hover04 from '../assets/2004.png'
import hover05 from '../assets/2005.png'
import hover07 from '../assets/2007.png'
import hover09 from '../assets/2009.png'
import hover15 from '../assets/2015.png'
import hover20 from '../assets/2020.png'
import hover21 from '../assets/2021.png'
import hover22 from '../assets/2022.png'
import hover23 from '../assets/2023.png'
import timeline from '../assets/timeline.png'
import * as S from './styled'

export default function Timeline (): ReactElement {
  return <S.TimelineContainer>
  <UnderlinedTitle>Nossa História</UnderlinedTitle>

  <S.TimelineImageContainer>
    <figure>
      <img src={timeline} alt="" />
    </figure>

    <S.HoverBalloon
      aria-label="Fundada no bairro da Mooca, em uma área de 4 mil M² iniciamos a produção em PP Laminado."
      $img={hover87}
      $left={106}
      $paddingBottom
      $top={12}
    />

    <S.HoverBalloon
      aria-label="Mudança da Fábrica para Caapicuíba em uma área de 20 mil M²;"
      $img={hover97}
      $left={348}
      $paddingTop
      $top={90}
    />

    <S.HoverBalloon
      aria-label="1² Linha de Extrusão Laminado;"
      $img={hover02}
      $left={587}
      $paddingBottom
      $top={12}
    />

    <S.HoverBalloon
      aria-label="1² Linha de Extrusão Alveolar;"
      $img={hover03}
      $left={830}
      $paddingTop
      $top={90}
    />

    <S.HoverBalloon
      aria-label="1² Linha de reciclagem e reprocesso de perdas;"
      $img={hover04}
      $left={1068}
      $paddingBottom
      $top={12}
    />

    <S.HoverBalloon
      aria-label="Início da unidade de embalagens Retornáveis Industriais;"
      $img={hover05}
      $left={1068}
      $paddingBottom
      $top={236}
    />

    <S.HoverBalloon
      aria-label="Marco de 95% de Reciclagem de aparas;"
      $img={hover07}
      $left={830}
      $paddingTop
      $top={314}
    />

    <S.HoverBalloon
      aria-label="Mudança da fábrica para Cambuí/MG em uma área de 29 mil M²;"
      $img={hover09}
      $left={587}
      $paddingBottom
      $top={236}
    />

    <S.HoverBalloon
      aria-label="Início de Produção de Separadores Retornáveis Latas;"
      $img={hover15}
      $left={348}
      $paddingTop
      $top={314}
    />

    <S.HoverBalloon
      aria-label="Início do fornecimento de Revestimentos para Linha Branca;"
      $img={hover20}
      $left={106}
      $paddingBottom
      $top={236}
    />

    <S.HoverBalloon
      aria-label="Homologação ISO 9001/ Homolocação Separador Retornável Alveolar garrafas;"
      $img={hover21}
      $left={106}
      $paddingBottom
      $top={460}
    />

    <S.HoverBalloon
      aria-label="Aumento de capacidade na produção de separadores. Marco na produção de 1 bilhão de arquivos"
      $img={hover22}
      $left={348}
      $paddingTop
      $top={540}
    />

    <S.HoverBalloon
      aria-label="Novas Linhas de Extrusão e Corte. Marco de processamento de 1 milhão de kg/mês;"
      $img={hover23}
      $left={587}
      $paddingBottom
      $top={460}
    />
  </S.TimelineImageContainer>
</S.TimelineContainer>
}
