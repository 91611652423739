import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 100px 28px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1110px;

  h1, h2, h3, h4, li {
    color: var(--text-primary);
  }

  h1 {
    font-size: 21pt;
    font-weight: 200;
  }

  h2 {
    font-size: 18pt;
    font-weight: 300;
  }

  h3 {
    font-size: 15pt;
    font-weight: 300;
  }

  h4 {
    font-size: 12pt;
    font-weight: 400;
  }
`

export const NewsBanner = styled.figure`
  aspect-ratio: 1110 / 400;
  align-self: center;
  border-radius: 16px;
  display: flex;
  flex: 1;
  height: 100%;
  max-height: 400px;
  overflow: hidden;
  width: 100%;

  img {
    object-fit: cover;
    object-position: top;
    transition: all .8s linear;
    width: 100%;

    &:hover {
      scale: 1.1;
    }
  }
`

export const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ol li + li, ul li + li {
    margin-top: 16px;
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  a {
    width: fit-content;

    button {
      background: linear-gradient(45deg, var(--secondary), var(--primary));
      border: none;
      border-radius: 36px;
      color: var(--white);
      cursor: pointer;
      font-size: 10.5pt;
      font-weight: 700;
      padding: 12px 40px;
      text-transform: uppercase;
      width: fit-content;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }
`
