/* eslint-disable @typescript-eslint/naming-convention */
import { ReactElement, useEffect, useRef, useState } from 'react'
import UnderlinedTitle from '../../components/UnderlinedTitle'
import { PageModel } from '../../data/entities/PageModel'
import { PageState } from '../../data/enums/PageState'
import { LoadBlogPosts } from '../../domain/use-case/LoadBlogPosts'
import NewsItem from './NewsItem'
import * as S from './styled'

interface BlogProps {
  loadBlogPosts: LoadBlogPosts
}

export default function Blog ({ loadBlogPosts }: BlogProps): ReactElement {
  const [posts, setPosts] = useState<PageModel[]>([])
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [visiblePosts, setVisiblePosts] = useState<boolean[]>([])
  const observers = useRef<IntersectionObserver[]>([])

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'))

          setVisiblePosts((prev) => {
            const newVisiblePosts = [...prev]
            newVisiblePosts[index] = true

            return newVisiblePosts
          })

          observers.current.forEach((observer) => observer.unobserve(entry.target))
        }
      })
    }

    const observerOptions: IntersectionObserverInit = {
      threshold: 0.1
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    observers.current = posts.slice(1).map((_, index) => {
      const element = document.querySelector(`[data-index="${index + 1}"]`)

      if (element != null) observer.observe(element)

      return observer
    })

    return () => observers.current.forEach((obs) => obs.disconnect())
  }, [])

  useEffect(() => {
    (async () => {
      setState(PageState.LOADING)
      const response = await loadBlogPosts.handle({ page: 1 })
      setPosts(response.posts)
      setVisiblePosts([true, ...Array(response.posts.length - 1).fill(true)])
      setState(PageState.READY)
    })()
  }, [])

  if (state === PageState.LOADING) return <>Carregando...</>

  return <S.Container>
    <UnderlinedTitle>Últimas notícias</UnderlinedTitle>

    <S.Content>
      {posts.map(({ post_excerpt, ID, thumbnail, post_title, post_name }, i) => <NewsItem
        data-index={i}
        description={post_excerpt}
        key={ID}
        image={thumbnail}
        newsId={ID}
        title={post_title}
        name={post_name}
        visible={visiblePosts[i]}
      />)}
    </S.Content>
  </S.Container>
}
