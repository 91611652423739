import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import Info from './Info'
import states from './Info/states'
import data from './data'
import * as S from './styled'

export type State = keyof typeof states

export default function Agents (): ReactElement {
  const [selectedState, setSelectedState] = useState<State>('ac')
  const svgRef = useRef<SVGSVGElement>(null)

  const filteredInfo = useMemo(() => data.filter((info) => info.state === selectedState), [selectedState])

  useEffect(() => {
    const svgElement = svgRef.current

    const handlePathClick = (event: MouseEvent): void => {
      const path = event.target as SVGPathElement
      if (path.tagName === 'path' && path.classList.contains('s1')) {
        const state = path.classList[1] as State
        setSelectedState(state)
      }
    }

    if (svgElement != null) {
      svgElement.addEventListener('click', handlePathClick)
    }

    return () => {
      if (svgElement != null) {
        svgElement.removeEventListener('click', handlePathClick)
      }
    }
  }, [])

  return <S.Container>
    <S.UnderlinedTitle>Representantes</S.UnderlinedTitle>

    <h3>Clique no mapa abaixo e descubra o representante <strong>Polibras</strong> mais perto de você:</h3>

    <S.Content>
      <S.BrazilMap ref={svgRef} $selectedState={selectedState} />

      <S.InfoContainer>
        <h3>{states[selectedState]}</h3>

        {filteredInfo.map((info, index, array) => <>
          <Info key={info.zoneCity} {...info} />

          {index < array.length - 1 && <hr />}
        </>
        )}
      </S.InfoContainer>
    </S.Content>

  </S.Container>
}
