import { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import Product from './components/ProductsList/Product'
import { makeHome } from './main/factory/makeHome'
import { makeLoadBlogPost } from './main/factory/makeLoadBlogPost'
import { makeLoadBlogPosts } from './main/factory/makeLoadBlogPosts'
import { makeLoadPageBySlug } from './main/factory/makeLoadPageBySlug'
import { makeLoadProductsByCartegory } from './main/factory/makeLoadProductsByCartegory'
import { makeSearch } from './main/factory/makeSearch'
import { makePaperPage } from './main/factory/pages/makePaperPage'
import About from './pages/About'
import Blog from './pages/Blog'
import BusinessSolutions from './pages/BusinessSolutions'
import Coating from './pages/BusinessSolutions/Coating'
import Pallet from './pages/BusinessSolutions/Pallet'
import Plates from './pages/BusinessSolutions/Plates'
import SeparatorSpreadsheets from './pages/BusinessSolutions/SeparatorSheets'
import Storage from './pages/BusinessSolutions/Storage'
import Contact from './pages/Contact'
import Cricut from './pages/Cricut'
import News from './pages/News'
import Category from './pages/Paper/Category'
import TermsOfPrivacy from './pages/TermsOfPrivacy'

const loadProductsByCartegory = makeLoadProductsByCartegory()
const loadBlogPosts = makeLoadBlogPosts()
const loadBlogPost = makeLoadBlogPost()
const loadPageBySlug = makeLoadPageBySlug()

export default function Router (): ReactElement {
  return <Routes>
    <Route element={makeHome()} path="/" />
    <Route element={<About loadPageBySlug={makeLoadPageBySlug()} />} path="/nossa-empresa" />

    <Route path="papelaria">
      <Route element={makePaperPage()} index />
      <Route path="cricut">
        <Route element={<Cricut loadProductsByCartegory={loadProductsByCartegory} loadPageBySlug={loadPageBySlug} />} index />
      </Route>

      <Route element={<Product />} path=":id" />
    </Route>

    <Route path="category/:slug">
      <Route element={<Category categorySlug={'papelaria'} loadProductsByCartegory={loadProductsByCartegory} />} index />
    </Route>

    <Route path="solucoes-para-negocios">
      <Route element={<BusinessSolutions />} index />
      <Route element={<Storage />} path="armazenagem" />
      <Route element={<Plates />} path="chapas" />
      <Route element={<SeparatorSpreadsheets />} path="folha-separadora" />
      <Route element={<Pallet />} path="manga-pallet" />
      <Route element={<Coating />} path="revestimento" />
    </Route>

    <Route path="/blog">
      <Route element={<Blog loadBlogPosts={loadBlogPosts} />} index />
      <Route element={<News loadBlogPost={loadBlogPost} />} path=":newTitle" />
    </Route>

    <Route element={<Contact />} path="/contato" />
    <Route element={makeSearch()} path="/busca" />

    <Route element={<TermsOfPrivacy />} path="/termos-de-privacidade" />
  </Routes>
}
