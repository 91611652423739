import styled from 'styled-components'
import bannerBg from './assets/BANNER2.png'

export const BannerOne = styled.figure`
  img {
    width: 100%;
  }
`

export const DescriptionContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 28px 14%;

  p {
    font-size: 22pt;
    text-align: center;

    strong {
      color: var(--text-title);
      font-weight: 800;
    }

    @media (max-width: 764px) {
      font-size: 12pt;
    }
  }
`

export const MissionContainer = styled.div`
  align-items: center;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  gap: 64px;
  height: 420px;
  padding: 32px 72px 32px 52px;
  width: 100%;

  div {
    width: 36%;
    p {
      color: var(--white);
      font-size: 17pt;
      text-align: center;

      strong {
        font-weight: 800;
      }
    }
  }

  figure.slide-in-image {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    position: relative;
    width: 50%;

    img {
      left: -116%;
      max-width: 650px;
      position: relative;
      transition: left .8s linear;
      width: 100%;

      &.visible {
        left: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    gap: 32px;
    height: initial;
    flex-direction: column;
    padding-top: 0;

    p {
      width: 80%;
    }
  }

  @media (max-width: 764px) {
    height: fit-content;
    padding: 32px;

    figure.slide-in-image {
      width: 100%;
    }

    p {
      font-size: 12pt;
      width: 90%;
    }
  }
`

export const CauseAndMissionContainer = styled.div`
  background-color: var(--white);
  display: flex;
  justify-content: center;
  gap: 12%;
  padding: 32px;

  figure {
    width: fit-content;

    img {
      max-width: 128px;
      width: 100%;
    }
  }

  h2 {
    color: var(--text-title);
    font-size: 27pt;
    text-align: center;
  }

  p {
    font-size: 15pt;
    text-align: center;
  }

  @media (max-width: 764px) {
    flex-direction: column;
    gap: 16px;

    h2 {
      font-size: 15pt;
    }

    p {
      font-size: 12pt;
    }
  }
`

export const CauseContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    max-width: 280px;
  }
`

export const MissionDescriptionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    max-width: 460px;
  }
`

export const PurposeContainer = styled.div`
  align-items: center;
  aspect-ratio: 1370 / 458;
  background-image: url(${bannerBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 28px 20%;
  width: 100%;

  h1 {
    color: var(--white);
    font-size: 35pt;
    text-align: center;
  }

  p {
    color: var(--white);
    font-size: 15pt;
    text-align: center;
  }

  @media (max-width: 764px) {
    padding: 28px 32px;

    h1 {
      font-size: 21pt;
    }

    p {
      font-size: 12pt;
    }
  }
`

export const CertificationsContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  gap: 16px;
`

export const LogosContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8%;
  width: 100%;

  figure {
    width: fit-content;

    img {
      width: 100%;
    }
  }
`

export const StructureContainer = styled.div`
  align-items: center;
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 210px;
  justify-content: center;
  padding: 28px 32px;

  h1 {
    border-color: var(--white);
    color: var(--white);
    padding-inline: 84px;


    &::before {
      width: 76%;
    }
  }

  @media (max-width: 764px) {
    h1 {
      padding-inline: 40px;
    }
  }
`

export const StructureContent = styled.div`
  align-items: center;
  display: flex;
  gap: 72px;

  p {
    color: var(--white);
    font-size: 20pt;
    text-align: center;
  }

  hr {
    height: 100%;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 17pt;
    }
  }

  @media (max-width: 764px) {
    justify-content: space-around;
    gap: 16px;

    p {
      font-size: 12pt;
    }
  }
`
