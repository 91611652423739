import InfoProps from './Info/props'

export default [
  {
    state: 'sp',
    zoneCity: 'CENTRO/NORTE',
    companyName: 'P R CORTEZ PROMOCAO DE VENDAS',
    agent: 'CORTEZ',
    email: 'prcortez.cortez@gmail.com',
    ddd: '11',
    phone: '',
    cellphone: '9-8266-6218',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'ABC/LESTE',
    companyName: 'JOHNSTON REPRESENTACAO COMERCIAL S/S LTDA - ME',
    agent: 'JOHNSTON ',
    email: 'johnstonrepres@terra.com.br',
    ddd: '11',
    phone: '4368-1755',
    cellphone: '9-4704-0634',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'OESTE',
    companyName: 'LUBERG BUSINESS COMERCIO E REPRESENTACOES LTDA',
    agent: 'LUCIENE MORAES',
    email: 'lubergbusiness@uol.com.br',
    ddd: '11',
    phone: '',
    cellphone: '9-8708-1097',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'SUL',
    companyName: 'SOCRAM REPRES. S/C LTDA.',
    agent: 'SANTOS',
    email: 'socramrepres@terra.com.br',
    ddd: '11',
    phone: '4121-6098',
    cellphone: '9 9942-8959',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'pr',
    zoneCity: 'SUL/CURITIBA',
    companyName: 'J. PORTO REPRES. COMS. LTDA',
    agent: 'RODRIGO PORTO',
    email: 'jportorepresentacao@gmail.com',
    ddd: '41',
    phone: '3242-2835',
    cellphone: '3242-2835',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'pr',
    zoneCity: 'OESTE/CASCAVEL',
    companyName: 'A D C REPRESENTACOES COMERCIAIS LTDA',
    agent: 'LUIZ CESAR',
    email: 'adcf.repres@uol.com.br',
    ddd: '45',
    phone: '3037-4573',
    cellphone: '9-9156-1160',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'pr',
    zoneCity: 'NORTE/LONDRINA',
    companyName: 'A CAMARGO REPRESENTAÇÕES LTDA',
    agent: 'ANDRÉ CAMARGO',
    email: 'acamargorep@hotmail.com',
    ddd: '43',
    phone: '3421-7544',
    cellphone: '9-853-2272 ',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'rs',
    zoneCity: 'CENTRAL FRONTEIRA',
    companyName: 'AZEREDO REPRESENTACOES LTDA',
    agent: 'CLAUDIO AZEREDO',
    email: 'claudio@azeredo.org',
    ddd: '55',
    phone: '3225-1126',
    cellphone: '9-9977-7652',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'rs',
    zoneCity: 'REG. SUL/FRON. SUL',
    companyName: 'M.M. PINTO & CIA LTDA',
    agent: 'MARCOS',
    email: 'mmpintorep@hotmail.com',
    ddd: '55',
    phone: '3251-2272',
    cellphone: '9-9989-9902',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'rs',
    zoneCity: 'REG. ALTA/TAQUARI e SERRA',
    companyName: 'LUCAVI REPRESENTACOES LTDA',
    agent: 'LUIZ VIERA',
    email: 'luizcavieira@terra.com.br',
    ddd: '51',
    phone: '3762-1148',
    cellphone: '9-9982-0646',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'rs',
    zoneCity: 'PORTO ALEGRE',
    companyName: 'REPRESENTACOES ROLIMAR LTDA.',
    agent: 'ROBERTO/RITA',
    email: 'ritac@rolimar.com.br',
    ddd: '51',
    phone: '3226-2944',
    cellphone: '9-9981-1652',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'sc',
    zoneCity: 'ITAJAI',
    companyName: 'L & K REPRESENTACOES LTDA',
    agent: 'LEANDRO E KAKA',
    email: 'lkrepresentacoes.sc@gmail.com',
    ddd: '47',
    phone: '',
    cellphone: '3228-1105',
    regionalManager: 'LUANA',
    internalSupport: 'ANGELICA - (11) 9-7126-1491 angelica.silva@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'BAURU',
    companyName: 'CR3 - CONSULTORIA DE VENDAS E REPRESENTACOES LTDA - ME',
    agent: 'ALESSANDRA',
    email: 'alessandra@cr3.com.br',
    ddd: '14',
    phone: '',
    cellphone: '9 -9151-8689',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'S.J.R.PRETO',
    companyName: 'BRC BARONI RIO PRETO REPRESENTACOES LTDA',
    agent: 'PAULO BARONI',
    email: 'p.cbaroni@terra.com.br',
    ddd: '17',
    phone: '3215-6685',
    cellphone: '9- 9133-5006',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'BAIXADA',
    companyName: 'CAMBI REPRESENTACOES COMERCIAIS DE MERCADORIAS EM GERAL',
    agent: 'EDUARDO CAMBI',
    email: 'cambirepresentacoes@yahoo.com.br',
    ddd: '13',
    phone: '3455-1145',
    cellphone: '9-8125-1280',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'RIBEIRÃO PRETO',
    companyName: 'F. R. DOS SANTOS REPRESENTACOES COMERCIAIS',
    agent: 'FLÁVIO RODRIGUES',
    email: 'flavio.10@hotmail.com',
    ddd: '16',
    phone: '3979-5394',
    cellphone: '9 9153-2725',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'sp',
    zoneCity: 'CAMPINAS ',
    companyName: 'GGG REPRESENTACOES COMERCIAIS LTDA',
    agent: 'JEWELLYS',
    email: 'jewellys.parizotto@gmail.com',
    ddd: '19',
    phone: '3256-4832',
    cellphone: '9-9772-8131',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'mg',
    zoneCity: 'MONTES CLAROS',
    companyName: 'TIAHOOP REPRESENTACOES LTDA',
    agent: 'JACKSON',
    email: 'jackson021182.tiahoop@hotmail.com',
    ddd: '31',
    phone: '3212-8818',
    cellphone: '99148-7177',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'mg',
    zoneCity: 'UBERLÂNDIA',
    companyName: 'REPRESENTACOES CESAR JUNIOR LTDA',
    agent: 'HÉLCIO',
    email: 'helcio@repcesarjunior.com.br',
    ddd: '34',
    phone: '3231-0066',
    cellphone: '9-9194-5613',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'mg',
    zoneCity: 'JUIZ DE FORA ',
    companyName: 'REP. JOSE MARIA FILGUEIRAS & CIA.LTDA',
    agent: 'JOSÉ MARIA',
    email: 'vendas@jmariavendas.com',
    ddd: '32',
    phone: '3216-4014',
    cellphone: '9-9987-5792',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'mg',
    zoneCity: 'SUL MINAS',
    companyName: 'FREITAS E SILVA REPRESENTACAO COMERCIAL POR CONTA DE TERCEIR',
    agent: 'MAURICIO',
    email: 'mauriciofreitasf40@yahoo.com.br',
    ddd: '35',
    phone: '3422-4497',
    cellphone: '9-9744-5282',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'mg',
    zoneCity: 'BELO HORIZONTE',
    companyName: 'CARTELL REPRESENTAÇÕES LTDA.',
    agent: 'PAULINHO',
    email: 'paulochiaretti59@gmail.com',
    ddd: '31',
    phone: '3222-0905',
    cellphone: '9-9978-1380',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'rj',
    zoneCity: 'NORTE DO RIO',
    companyName: 'MRNI ZAMPAGLIONE REPRESENTACOES LTDA.',
    agent: 'AILTON MARCELO',
    email: 'ailton.vendas@yahoo.com.br',
    ddd: '22',
    phone: '2771-0146',
    cellphone: '9-9836-5950',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'rj',
    zoneCity: 'BAIXADA',
    companyName: 'DT COMERCIO E REPRESENTACOES LTDA ME',
    agent: 'JULIO CESAR',
    email: 'juliogoes7@hotmail.com',
    ddd: '21',
    phone: '',
    cellphone: '9-6448-7922',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'rj',
    zoneCity: 'CENTRO',
    companyName: 'UNA RIO REPRESENTACOES DE VENDAS LTDA',
    agent: 'UMBERTO',
    email: 'umberto.sordi@gmail.com',
    ddd: '21',
    phone: '2402-4538',
    cellphone: '9-8874-6619',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'rj',
    zoneCity: 'BAIXADA FLUMINENSE',
    companyName: 'L. E. DA SILVA ALMEIDA REPRESENTACAO COMERCIAL',
    agent: 'LUIZ EDUARDO',
    email: 'ls03representacao@gmail.com',
    ddd: '21',
    phone: '',
    cellphone: '9-9487-0048',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'es',
    zoneCity: 'SERRA',
    companyName: 'MB VIX - SERVICOS E REPRESENTACOES',
    agent: 'MARCELLO',
    email: 'mbvixvendas@gmail.com',
    ddd: '27',
    phone: '',
    cellphone: '9-9969-5588',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'ms',
    zoneCity: 'CAMPO GRANDE ',
    companyName: 'V MELLO REPRESENTAÇOES COMERCIAIS LTDA',
    agent: 'ANDERSON ',
    email: 'nova.rep.ms@hotmail.com',
    ddd: '67',
    phone: '3305-0006',
    cellphone: '9-8167-0936',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'go',
    zoneCity: 'GOIANIA',
    companyName: 'L.C.M. REPRES. COMERCIAIS LTDA.',
    agent: 'LOURIVAL',
    email: 'lourival.rezende@uol.com.br',
    ddd: '62',
    phone: '3246-0798',
    cellphone: '9-8144-4000',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'df',
    zoneCity: 'BRASILIA',
    companyName: 'PORTO REPRESENTACOES LTDA',
    agent: 'OSEIAS MORAIS',
    email: 'oseiasmoralis@gmail.com',
    ddd: '62',
    phone: '',
    cellphone: '9-9682-3807',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'mt',
    zoneCity: 'CUIABÁ',
    companyName: 'NOBEL REPRES. E SERVICOS LTDA - ME.',
    agent: 'VERA',
    email: 'nobelrepresentacoes@gmail.com',
    ddd: '65',
    phone: '3626-1117',
    cellphone: '9-9901-1391',
    regionalManager: 'ESTER',
    internalSupport: 'RILDO (11) 99165-9871 rildo.batista@polibras.com.br'
  },
  {
    state: 'ma',
    zoneCity: 'NORTE',
    companyName: 'RANNY REPRESENTACOES LTDA',
    agent: 'ELBA',
    email: 'rannyrepresentacoes@outlook.com',
    ddd: '98',
    phone: '',
    cellphone: '9-8175-3144',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'pe',
    zoneCity: 'RECIFE',
    companyName: 'E. ALCOFORADO DE MELO REPRESENTACOES - ME',
    agent: 'GASPAR',
    email: 'gaspar@gasparqueirozcia.com.br',
    ddd: '81',
    phone: '3231-7229',
    cellphone: '9-9282-3885',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'pa',
    zoneCity: 'SANTARÉM/MACAPA',
    companyName: 'MARIA DO SOCORRO A. DE S. REPRES. ME',
    agent: 'SOCORRO',
    email: 'sosrepresentacoespa@hotmail.com',
    ddd: '91',
    phone: '3235-3488',
    cellphone: '9-8144-8214',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'ap',
    zoneCity: 'SANTARÉM/MACAPA',
    companyName: 'MARIA DO SOCORRO A. DE S. REPRES. ME',
    agent: 'SOCORRO',
    email: 'sosrepresentacoespa@hotmail.com',
    ddd: '91',
    phone: '3235-3488',
    cellphone: '9-8144-8214',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'ba',
    zoneCity: 'SALVADOR',
    companyName: 'SCHLEU SERRA REPRESENTACOES LTDA',
    agent: 'ERWIN',
    email: 'schleurepresentacoes@gmail.com',
    ddd: '71',
    phone: '3215-9200',
    cellphone: ' 9-9987-5398',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'ma',
    zoneCity: 'INTERIOR',
    companyName: 'PAULO HENRIQUE ALVES DA SILVA EIRELI',
    agent: 'PAULO HENRIQUE',
    email: 'paulohenriquerep@gmail.com',
    ddd: '98',
    phone: '',
    cellphone: '9-8866-4325',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'ma',
    zoneCity: 'SÃO LUIS',
    companyName: 'I. A. SANTOS E CIA LTDA',
    agent: 'INALDO',
    email: 'iasantosecia@hotmail.com',
    ddd: '98',
    phone: '3228-9750',
    cellphone: '9-8151-5160',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'al',
    zoneCity: 'MACEIÓ',
    companyName: 'FABIO TENORIO & CIA LTDA',
    agent: 'FABIO TENORIO',
    email: 'fabiotenoriorep@hotmail.com',
    ddd: '82',
    phone: '',
    cellphone: '9-8812-4166',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'pi',
    zoneCity: 'TEREZINA',
    companyName: 'FRANCISCO SILVA LIMA & CIA LTDA',
    agent: 'LIMA',
    email: 'limafrancisco649@gmail.com',
    ddd: '86',
    phone: '3225-3797',
    cellphone: '9-9982-0703',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'rn',
    zoneCity: 'NATAL',
    companyName: 'M.P.S. REPRESENTACOES LTDA.',
    agent: 'MÁRCIO',
    email: 'mpsrep@hotmail.com',
    ddd: '84',
    phone: '3615-1251',
    cellphone: '9-9982-8090',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'se',
    zoneCity: 'ARACAJÚ',
    companyName: 'ALMIRACI DE OLIVEIRA SILVA',
    agent: 'MIRA',
    email: 'miravendas@yahoo.com.br',
    ddd: '79',
    phone: '',
    cellphone: '9-9804-0464',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'ce',
    zoneCity: 'FORTALEZA',
    companyName: 'MONTEIRO LIMA REPRESENTACAO LTDA',
    agent: 'RUBEN',
    email: 'rubenvclm@gmail.com',
    ddd: '85',
    phone: '3276-6859',
    cellphone: '9-9981-4065',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'pb',
    zoneCity: 'JOÃO PESSOA ',
    companyName: 'TRIGUEIRO DO VALE & CIA LTDA',
    agent: 'SÁVIO',
    email: 'savio@trigueirodovale.com.br',
    ddd: '83',
    phone: '3247-9050',
    cellphone: '9-8825-0760',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'ro',
    zoneCity: 'JI-PARANÁ',
    companyName: 'GUIMARAES REPRESENTACOES COMERCIAIS LTDA ME',
    agent: 'AÍLTON',
    email: 'guimaraes@guimaraesrep.com.br',
    ddd: '69',
    phone: '3421-4058',
    cellphone: '9 9975-1420',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'ac',
    zoneCity: 'JI-PARANÁ',
    companyName: 'GUIMARAES REPRESENTACOES COMERCIAIS LTDA ME',
    agent: 'AÍLTON',
    email: 'guimaraes@guimaraesrep.com.br',
    ddd: '69',
    phone: '3421-4058',
    cellphone: '9 9975-1420',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'to',
    zoneCity: 'PALMAS',
    companyName: 'QUEIROZ E CARVALHO LTDA - ME',
    agent: 'CLAUDEMIR',
    email: 'centrooesterep@gmail.com',
    ddd: '63',
    phone: '3361-3065',
    cellphone: '9-8456-7197',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'am',
    zoneCity: 'MANAUS',
    companyName: 'FECOM COMERCIO E REPRESENTACOES LTDA - EPP',
    agent: 'KLEBSON',
    email: 'klebsonsousa2@gmail.com',
    ddd: '92',
    phone: '',
    cellphone: '9-8109-6437',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'pa',
    zoneCity: 'MARABA',
    companyName: 'F. A. DOS SANTOS DIAS & CIA LTDA - ME',
    agent: 'FERNANDO',
    email: 'fasdias2023@gmail.com',
    ddd: '94',
    phone: '',
    cellphone: '9-9231-7214',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  },
  {
    state: 'rr',
    zoneCity: 'BOA VISTA ',
    companyName: 'MORAES E MORAES LTDA ME',
    agent: 'FRANCISCO',
    email: 'fm.rep10@hotmail.com',
    ddd: '95',
    phone: '3224-6727',
    cellphone: '9-9121-1734',
    regionalManager: 'THIAGO',
    internalSupport: 'Valéria (11) 9-3475-3665  valeria.alves@polibras.com.br'
  }
] satisfies InfoProps[]
