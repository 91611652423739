import styled from 'styled-components'
import UT from '../../../components/UnderlinedTitle'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
`

export const UnderlinedTitle = styled(UT)`
  font-weight: 800;
  line-height: 72px;
  padding-inline: 80px;
  text-align: center;

  &::before {
    width: 80%;
  }

  @media (max-width: 580px) {
    line-height: normal;
    white-space: normal;
  }

  @media (max-width: 400px) {
    font-size: 15pt;
    padding-inline: 20px;

    &::before {
      width: 80%;
    }
  }
`
