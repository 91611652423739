import { ReactElement } from 'react'
import { PageState } from '../../data/enums/PageState'
import useLoadPageBySlug from '../../hooks/useLoadPageBySlug'
import solutions from './solutions'
import * as S from './styled'

export default function BusinessSolutions (): ReactElement {
  const { data, state } = useLoadPageBySlug<any>('solucoes-para-negocios')

  if (state === PageState.LOADING) return <>Carregando...</>
  if (state === PageState.NO_CONTENT) return <>Nenhum conteúdo disponivel</>

  return <S.Container>
    <S.TopBannerBg $bannerBg={data.fields.banner.imagem}>
      <S.TopBannerContent dangerouslySetInnerHTML={{ __html: data.fields.banner.titulo }}></S.TopBannerContent>
    </S.TopBannerBg>

    <S.DescriptionContainer>
      <div dangerouslySetInnerHTML={{ __html: data.page.post_content }}></div>
    </S.DescriptionContainer>

    <S.SectionsContainer>
      <S.SolutionsSection>
        <S.UnderlinedTitle>
          Destacamos algumas das nossas soluções
        </S.UnderlinedTitle>

        <S.SolutionsGrid>
          {solutions.map(({ image, name, path }) => <S.SolutionItem
            key={name}
            to={`/solucoes-para-negocios/${path}`}
          >
            <figure>
              <img src={image} alt="" />
            </figure>

            <p>{name}</p>
          </S.SolutionItem>)}
        </S.SolutionsGrid>
      </S.SolutionsSection>

      <S.AttributesSection>
        <S.AttributesContent>
          <h1>Atributos de nossos produtos:</h1>

          <ul>
            {data.fields.atributos.valores.map((item: { icone: string, texto: string }) => <li>
              <img src={item.icone} alt="" />

              {item.texto}
            </li>)}
          </ul>
        </S.AttributesContent>

        <figure>
          <img src={data.fields.atributos.imagem} alt="" />
        </figure>
      </S.AttributesSection>

      <S.BenefitsSection>
        <S.BenefitsTitleContainer>
          <S.BenefitsTitle>
            Benefícios estratégicos para seu negócio:
          </S.BenefitsTitle>
        </S.BenefitsTitleContainer>

        <S.BenefitsContent>
          {data.fields.beneficios.map((item: { icone: string, texto: string }) => <S.Benefit>
            <img src={item.icone} alt="" />

            <p>{item.texto}</p>
          </S.Benefit>)}
        </S.BenefitsContent>
      </S.BenefitsSection>

      <S.ConclusionSection>
        <figure>
          <img src={data.fields.inovacoes.imagem} alt="" />
        </figure>

        <p dangerouslySetInnerHTML={{ __html: data.fields.inovacoes.conteudo }}></p>
      </S.ConclusionSection>
    </S.SectionsContainer>
  </S.Container>
}
