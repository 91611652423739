import styled from 'styled-components'

export const Container = styled.div`
  background-image: linear-gradient(to bottom, var(--white) 14%, var(--background) 14%, var(--background) 88%, var(--white) 88%);
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
  padding: 0 28px;
  position: relative;
  width: 100%;

  figure {
    max-width: 50%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 955px) {
    align-items: center;
    background-image: linear-gradient(to bottom, var(--white) 8%, var(--background) 8%);
    flex-direction: column;
    justify-content: center;
    padding: 0 28px 32px;

    figure {
      align-self: center;
      max-width: 480px;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 764px) {
    figure {
      max-width: 400px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1370px;
  padding-left: 64px;
  width: 50%;

  h1 {
    font-size: 22.5pt;
    font-weight: 800;
  }

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    max-width: 545px;
  }

  ul {
    list-style: none;

    li {
      align-items: center;
      color: var(--text-secondary);
      display: flex;
      gap: 8px;
      font-size: 13.5pt;
      margin-top: 8px;
      white-space: nowrap;

      svg {
        max-width: 28px;
        min-width: 28px;
      }
    }
  }

  @media (max-width: 1270px) {
    h1 {
      font-size: 20pt;
    }

    p {
      font-size: 12pt;
    }

    ul li {
      font-size: 12pt;
    }
  }

  @media (max-width: 1170px) {
    h1 {
      font-size: 18pt;
    }

    p {
      font-size: 10.5pt;
    }

    ul {
      max-width: initial;

      li {
        font-size: 10.5pt;
      }
    }
  }

  @media (max-width: 954px) {
    padding: 0;
    width: initial;

    h1 {
      font-size: 15pt;
    }

    p {
      font-size: 12pt;
    }

    ul {
      margin-top: 0;
      max-width: initial;

      li {
        font-size: 12pt;
        margin-top: 16px;
        white-space: initial;
      }
    }
  }

  @media (max-width: 764px) {
    p {
      font-size: 10.5pt;
    }

    ul {
      margin-top: 8px;

      li {
        font-size: 10.5pt;
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 460px) {
    p {
      font-size: 9pt;
    }

    ul {
      margin-top: 4px;

      li {
        font-size: 9pt;
        margin-top: 4px;
      }
    }
  }
`
