import { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ApiErrorMessage from '../../../components/ApiErrorMessage'
import Loading from '../../../components/Loading'
import { CategoryModel } from '../../../data/entities/CategoryModel'
import { LoadCategories } from '../../../domain/use-case/LoadCategories'
import * as S from './styled'

enum Status {
  LOADING,
  READY,
  ERROR
}

interface CategoriesProps {
  loadCategories: LoadCategories
}

export default function Categories ({ loadCategories }: CategoriesProps): ReactElement {
  const [data, setData] = useState<CategoryModel[]>([])
  const [status, setStatus] = useState<Status>(Status.LOADING)

  useEffect(() => {
    (async () => {
      const response = await loadCategories.handle({ slug: 'papelaria' })
      setData(response as unknown as CategoryModel[])
      console.log(response as unknown as CategoryModel[])
      setStatus(Status.READY)
    })()
  }, [])

  return <S.Content>
    {(status === Status.LOADING) && <Loading />}

    {(status === Status.ERROR) && <ApiErrorMessage />}

    {(status === Status.READY) && <S.CategoriesContainer>
      {data.map(({ name, path, slug }) => <Link to={`/category/${slug}`} key={name}>
        <S.OverlayContainer>
          <img src={path} alt={name} />
        </S.OverlayContainer>
      </Link>)}
    </S.CategoriesContainer>}

  </S.Content>
}
