import styled from 'styled-components'
import UT from '../../../components/UnderlinedTitle'
import { ReactComponent as Map } from './assets/map.svg'

export const Container = styled.div`
  align-self: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  h3 {
    align-self: flex-start;
    color: var(--text-primary);
    font-weight: 400;
    font-size: 15pt;
    max-width: 586px;

    strong {
      color: var(--primary);
    }
  }
`

export const UnderlinedTitle = styled(UT)`
  width: fit-content;

  @media (max-width: 400px) {
    font-size: 18pt;
    line-height: 40px;
    padding-inline: 28px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 32px;

  @media (max-width: 764px) {
    flex-direction: column;
  }
`

export const BrazilMap = styled(Map)<{ $selectedState: string }>`
  flex: 1;
  width: 100%;

  path {
    cursor: pointer;
    fill: #ccc;
    transition: fill 0.2s;

    &:not(.name):hover {
      fill: var(--primary);
    }

    &.name,
    &.selected {
      fill: var(--primary);
      pointer-events: none;
    }

    &.${({ $selectedState }) => $selectedState} {
      fill: var(--primary);
      pointer-events: none;
    }
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 34px;

  h3 {
    color: var(--primary);
    font-size: 24pt;
    font-weight: 800;
    white-space: nowrap;
  }

  p {
    font-size: 12pt;
    font-weight: 400;

    span {
      color: var(--primary);
      font-weight: 600;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #B3B2B2;
    opacity: .6;
    width: 70%;
  }

  @media (max-width: 764px) {
    h3 {
      font-size: 21pt;
    }

    p {
      font-size: 10.5pt;
    }
  }
`
