/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useCallback, useEffect, useState } from 'react'
import ProductsList from '../../../components/ProductsList'
import { CategoryModel } from '../../../data/entities/CategoryModel'
import { LoadCategoriesByParent } from '../../../domain/use-case/loadCategoriesByParent'
import { LoadProductsByCartegory } from '../../../domain/use-case/LoadProductsByCartegory'
import * as S from './styled'
import Tabs from './Tabs'

enum Status {
  LOADING,
  READY,
  ERROR
}

export interface ReleasesProps {
  loadCategoriesByParent: LoadCategoriesByParent
  loadProductsByCartegory: LoadProductsByCartegory
}

export default function Releases ({ loadCategoriesByParent, loadProductsByCartegory }: ReleasesProps): ReactElement {
  const [activeView, setActiveView] = useState<number>(-1)
  const [data, setData] = useState<CategoryModel[]>([])
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const [status, setStatus] = useState<Status>(Status.LOADING)

  const handleTabClick = useCallback((index: number): void => {
    setIsTransitioning(true)

    setTimeout(() => {
      setActiveView(index)
    }, 300)
  }, [])

  useEffect(() => {
    (async () => {
      const response = await loadCategoriesByParent.handle({ slug: 'lancamento' })
      setData(response as unknown as CategoryModel[])
      setStatus(Status.READY)
    })()
  }, [])

  useEffect(() => {
    if (isTransitioning) {
      const timeout = setTimeout(() => {
        setIsTransitioning(false)
      }, 300)

      return () => clearTimeout(timeout)
    }
  }, [isTransitioning])

  if (status === Status.LOADING) return <>Carregando...</>
  if (status === Status.ERROR) return <>Ocorreu um erro ao tentar carregar os dados</>

  return <S.Container>
    <Tabs
      activeView={activeView}
      tabs={data}
      onClick={handleTabClick}
    />

    <S.Content>
      <S.ProductsContainer $isTransitioning={isTransitioning}>
        <ProductsList loadProductsByCartegory={loadProductsByCartegory} slug={data[activeView]?.slug} />
      </S.ProductsContainer>
    </S.Content>
  </S.Container>
}
