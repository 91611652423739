import '@fortawesome/fontawesome-free/css/all.min.css'
import { createGlobalStyle, css } from 'styled-components'
import '../assets/fonts/index.css'

const rootColors = {
  default: css`
    :root {
      --primary: #103272;
      --primary-hover: #1032724D;
      --secondary: #0077B6;

      --background: #E9E8E7;

      --black: #000;
      --danger: #dc3545;
      --grey: #D9D9D9;
      --text-primary: #3A3A3A;
      --text-secondary: #626060;
      --text-title: #103272;
      --white: #fff;
    }
  `
}

const GlobalStyle = createGlobalStyle`
  ${rootColors.default}

  * {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: var(--background);
  }

  #root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }

  main {
    flex: 1;
  }

  h1 {
    color: var(--text-title);
  }

  h2, h3, h4,
  h5, h6, a, p, span,
  label, input, textarea,
  select {
    color: var(--text-primary);
  }

  a {
    text-decoration: none;
  }
`

export default GlobalStyle
