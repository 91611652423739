import coating from './assets/coating.png'
import pallet from './assets/pallet.png'
import plates from './assets/plates.png'
import sheets from './assets/sheets.png'
import storage from './assets/storage.png'

export default [
  { image: storage, name: 'ARMAZENAGEM', path: 'armazenagem' },
  { image: plates, name: 'CHAPAS', path: 'chapas' },
  { image: sheets, name: 'FOLHAS SEPARADORAS', path: 'folha-separadora' },
  { image: pallet, name: 'MANGA PALLET', path: 'manga-pallet' },
  { image: coating, name: 'REVESTIMENTO', path: 'revestimento' }
]
