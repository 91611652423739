import { ReactElement, useEffect, useRef, useState } from 'react'
import { useModal } from '../../contexts/ModalContext'
import GalleryProps from './props'
import * as S from './styled'

export default function Gallery ({ images, maxWidth = 1000, title }: GalleryProps): ReactElement {
  const { closeModal, openModal } = useModal()
  const observers = useRef<IntersectionObserver[]>([])
  const [visibleImages, setVisibleImages] = useState<boolean[]>([true, ...Array(images.length - 1).fill(false)])

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'))

          setVisibleImages((prev) => {
            const newVisiblePosts = [...prev]
            newVisiblePosts[index] = true

            return newVisiblePosts
          })

          observers.current.forEach((observer) => observer.unobserve(entry.target))
        }
      })
    }

    const observerOptions: IntersectionObserverInit = {
      threshold: 0.1
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    observers.current = images.slice(1).map((_, index) => {
      const element = document.querySelector(`[data-index="${index + 1}"]`)

      if (element != null) observer.observe(element)

      return observer
    })

    return () => observers.current.forEach((obs) => obs.disconnect())
  }, [])

  const handleOpenModal = (site: string): void => openModal({
    body: <S.ModalZoom onClick={closeModal} className="modal-zoom" >
      <img src={site} alt="" />
    </S.ModalZoom>,
    content: {
      maxWidth,
      overflow: 'hidden'
    },
    overlay: {
      cursor: 'zoom-out'
    },
    shape: 'square'
  })

  return <S.Container>
    <h2>{title}</h2>

    <S.ImagesContainer>
      {images.map((site, i) => <S.OverlayContainer key={site} onClick={() => handleOpenModal(site)}>
        <S.Image $visible={visibleImages[i]} data-index={i}>
        <img src={site} alt="" />
      </S.Image>

      <S.Overlay />
    </S.OverlayContainer>)}
    </S.ImagesContainer>
  </S.Container>
}
