import { ReactElement } from 'react'
import PlatesProps from '../props'
import * as S from './styled'

export default function Laminated ({ data }: PlatesProps): ReactElement {
  return <S.Container>
    <S.Content>
      <S.TextContent>
        <h1>{data.fields.hero.titulo}</h1>

        <p dangerouslySetInnerHTML={{ __html: data.fields.hero.conteudo }}></p>
      </S.TextContent>

      <figure>
        <img src={data.fields.hero.imagem} alt="" />
      </figure>
    </S.Content>
  </S.Container>
}
