import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;

  textarea {
    min-height: 160px;
  }
`

export const Label = styled.label`
  font-size: 10.5pt;
  font-weight: 700;
`

export const Input = styled.input`
  background-color: transparent;
  border: 2px solid rgba(202, 193, 193, 1);
  color: var(--text-secondary);
  font-family: 'Open sans', sans-serif;
  font-size: 12pt;
  font-weight: 300;
  height: 45px;
  outline: none;
  padding-inline: 10px;
  width: 100%;

  &::placeholder {
    color: var(--text-secondary);
  }

  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  @media (max-width: 764px) {
    font-size: 12pt;
    height: 46px;
  }
`
