import { AxiosInstance } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop'
import ModalProvider from './contexts/ModalContext'
import { MenuItemModel } from './data/entities/MenuItemModel'
import { PageState } from './data/enums/PageState'
import Router from './router'
import api from './services/api'
import GlobalStyle from './styles'

interface LoadMain {
  handle: () => Promise<LoadMain.Result>
}

namespace LoadMain {
  export interface Result {
    menu: MenuItemModel[]
    phone: string
    office: string
    factory: string
  }
}

class ApiLoadMain implements LoadMain {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadMain.Result> {
    const response = await api.get('/wp-json/plb2024/v1/main/')
    return response.data
  }
}

const makeLoadMain = (): LoadMain => {
  return new ApiLoadMain(api)
}

const loadMain = makeLoadMain()

export default function App (): ReactElement {
  const [data, setData] = useState<LoadMain.Result>({} as unknown as LoadMain.Result)
  const [state, setState] = useState<PageState>(PageState.LOADING)
  useEffect(() => {
    (async () => {
      const response = await loadMain.handle()
      setData(response)
      setState(PageState.READY)
    })()
  }, [])

  if (state === PageState.LOADING) return <>...</>

  return <BrowserRouter>
    <ModalProvider>
      <ScrollToTop />
      <Header {...data} />
      <Router />
      <Footer {...data} />
      <GlobalStyle />
    </ModalProvider>
  </BrowserRouter>
}
