import { ReactElement, useCallback, useEffect, useState } from 'react'
import { CategoryModel } from '../../data/entities/CategoryModel'
import ApiErrorMessage from '../ApiErrorMessage'
import Loading from '../Loading'
import UnderlinedTitle from '../UnderlinedTitle'
import ProductsListProps from './props'
import * as S from './styled'

enum Status {
  LOADING,
  READY,
  ERROR,
  NO_CONTENT
}

const INITIAL_MAX_PRODUCTS = 6

export default function ProductsList ({ displayTitle = false, slug, loadProductsByCartegory }: ProductsListProps): ReactElement {
  const [data, setData] = useState<CategoryModel>({} as unknown as CategoryModel)
  const [maxProductsDisplayedCount, setMaxProductsDisplayedCount] = useState(INITIAL_MAX_PRODUCTS)
  const [status, setStatus] = useState<Status>(Status.LOADING)

  const handleViewMore = useCallback(() => {
    setMaxProductsDisplayedCount((prev) => prev + INITIAL_MAX_PRODUCTS)
  }, [])

  useEffect(() => {
    (async () => {
      setStatus(Status.LOADING)
      setMaxProductsDisplayedCount(INITIAL_MAX_PRODUCTS)
      const response = await loadProductsByCartegory.handle({ slug: slug || '' })
      setData(response as unknown as CategoryModel)
      setStatus(Status.READY)
    })()
  }, [slug])

  return <S.Container>
    {(status === Status.LOADING) && <Loading />}

    {(status === Status.ERROR) && <ApiErrorMessage />}

    {(status === Status.READY) && <>
      {displayTitle && <UnderlinedTitle>{data.name}</UnderlinedTitle>}

      <S.ProductsContainer>
        {data.products?.slice(0, maxProductsDisplayedCount).map(({ id, thumbs, name, permalink }) => <S.OverlayContainer key={id}>
          <S.Product href={permalink}>
            <figure>
              <img src={thumbs[0]} alt={name} />
            </figure>

            <S.Overlay>
              <S.IconContainer>
                <i className="fa fa-search-plus" />
              </S.IconContainer>
              <p>{name}</p>
            </S.Overlay>
          </S.Product>
        </S.OverlayContainer>)}
      </S.ProductsContainer>

      {(data.products?.length > 6) && <S.ShowMoreButton
        disabled={maxProductsDisplayedCount > data.products?.length}
        onClick={handleViewMore}>
        Ver mais
      </S.ShowMoreButton>}
    </>}
  </S.Container>
}
