import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;

  .container {
    grid-template-columns: repeat(auto-fit, 284px);
    max-width: 1000px;

    > div {
      &:last-child {
        grid-column: 2;

        @media (max-width: 1026px) {
          grid-column: auto;
        }
      }
    }
  }
`
