import { ReactElement } from 'react'
import PlatesProps from '../props'
import * as S from './styled'

export default function Alveolar ({ data }: PlatesProps): ReactElement {
  return <S.Plates2Container>
    <h1>{data.fields.chapas.titulo}</h1>

    <S.Plates2Content>
      <figure>
        <img src={data.fields.chapas.imagem} alt="" />
      </figure>

      <S.Plates2TextContent dangerouslySetInnerHTML={{ __html: data.fields.chapas.conteudo }}></S.Plates2TextContent>
    </S.Plates2Content>
  </S.Plates2Container>
}
