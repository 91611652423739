import styled from 'styled-components'
import UT from '../../../../components/UnderlinedTitle'
import IconItem from '../../IconItem'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 28px;
  width: 100%;
`

export const UnderlinedTitle = styled(UT)`
  align-self: center;
  font-weight: 800;
  line-height: 56px;
  padding-inline: 0;
  text-align: center;
  white-space: normal;
  width: fit-content;

  &::before {
    width: 56%;
  }

  @media (max-width: 600px) {
    br {
      display: none;
    }
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 8px;
  justify-content: center;
  width: 100%;

  > div {
    max-width: 236px;
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const Item = styled(IconItem)`
  svg {
    margin-top: ${({ text }) => text.includes('armazenamento') && '10%'};
  }

  @media (max-width: 764px) {
    svg {
    margin-top: ${({ text }) => text.includes('armazenamento') && '0'};
  }
  }
`
