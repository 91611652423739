import { useEffect, useState } from 'react'
import { PageState } from '../../data/enums/PageState'
import { LoadPageBySlug } from '../../domain/use-case/LoadPageBySlug'
import { makeLoadPageBySlug } from '../../main/factory/makeLoadPageBySlug'

const loadPageBySlug = makeLoadPageBySlug()

export default function useLoadPageBySlug<T> (slug: string): { data: LoadPageBySlug.Result<T>, state: PageState } {
  const [data, setData] = useState<LoadPageBySlug.Result<T>>({} as unknown as LoadPageBySlug.Result<T>)
  const [state, setState] = useState<PageState>(PageState.LOADING)

  useEffect(() => {
    (async () => {
      try {
        setState(PageState.LOADING)
        const response = await loadPageBySlug.handle(slug)
        setData(response)
        setState(PageState.READY)
      } catch (error) {
        const err = error as Error
        if (err.name === 'Sem conteúdo') {
          setState(PageState.NO_CONTENT)
        }
      }
    })()
  }, [])

  return { data, state }
}
