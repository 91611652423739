import styled from 'styled-components'
import UT from '../../components/UnderlinedTitle'

export const Container = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;

  ul.control-dots {
    display: flex;
    gap: 8px;
    left: 0;
    right: 0;
    margin-inline: 50%;
  }
`

export const UnderlinedTitle = styled(UT)`
  font-weight: 800;
  line-height: 72px;
  text-align: center;

  @media (max-width: 764px) {
    padding-inline: 32px;

    &::before {
      width: 84%;
    }
  }

  @media (max-width: 580px) {
    line-height: normal;
    white-space: normal;
  }

  @media (max-width: 400px) {
    font-size: 15pt;
    padding-inline: 20px;

    &::before {
      width: 80%;
    }
  }
`

export const ArrowBack = styled.figure`
  bottom: 0;
  cursor: pointer;
  height: fit-content;
  left: 36px;
  margin: auto 0;
  max-width: fit-content;
  position: absolute;
  top: 0;
  z-index: 1;


  @media (max-width: 1024px) {
    max-width: 16px;
  }
`

export const ArrowForward = styled.figure`
  bottom: 0;
  cursor: pointer;
  height: fit-content;
  margin: auto 0;
  max-width: fit-content;
  position: absolute;
  right: 36px;
  top: 0;
  z-index: 1;


  @media (max-width: 1024px) {
    max-width: 16px;
  }
`

export const Indicator = styled.figure`
  cursor: pointer;
  max-width: fit-content;


  @media (max-width: 1024px) {
    max-width: 8px;
  }
`

export const DescriptionContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 54px 28px 68px;
  width: 100%;

  p {
    font-size: 18.75pt;
    line-height: 34px;
    max-width: 1180px;
    text-align: center;

    strong {
      font-weight: 700;
    }

    @media (max-width: 764px) {
      font-size: 12pt;
    }
  }
  `

export const LinesContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 68px 28px 20px;
  width: 100%;
`

export const ReleasesContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 68px 28px 20px;
  width: 100%;
`

export const DownloadButton = styled.a`
  button {
    background-color: #FFBA09;
    border: none;
    border-radius: 12px;
    color: var(--white);
    cursor: pointer;
    font-size: 15pt;
    font-weight: 700;
    padding: 14px 20px;
  }
`
