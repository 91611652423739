import { ReactElement } from 'react'
import NavItem from './NavItem'
import NavListProps from './props'
import * as S from './styled'

export default function NavList ({ handleToggleMenu, menu, ...rest }: NavListProps): ReactElement {
  return <S.NavContainer {...rest}>
    <ul>
      {menu.map((item, index: number) => <NavItem
        key={index}
        label={item.name}
        to={item.link}
        submenuItems={item.child_items.map(child => ({ label: child.name, to: child.link }))}
      />)}

    </ul>
  </S.NavContainer>
}
