import { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import InputText from '../../components/InputTextCorreto'
import { PageModel } from '../../data/entities/PageModel'
import { PageState } from '../../data/enums/PageState'
import NewsItem from '../Blog/NewsItem'
import { SearchProps } from './props'
import * as S from './styled'

interface SearchParams {
  q: string
  posts: PageModel[]
}

export default function Search ({ loadSearch }: SearchProps): ReactElement {
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState<SearchParams>({
    q: searchParams.get('q') ?? ''
  } as unknown as SearchParams)

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ q: data.q })
  }, [data.q])

  useEffect(() => {
    (async () => {
      setState(PageState.LOADING)
      try {
        const response = await loadSearch.handle({ content: searchParams.get('q') ?? '' })
        setData((data) => ({ ...data, posts: response }))
        setState(PageState.READY)
      } catch (err) {
        const error = err as Error
        switch (error.message) {
          case 'Sem conteudo':
            setState(PageState.NO_CONTENT)
            return
          default:
            console.log('Ocorreu um erro:', error.message)
        }
      }
    })()
  }, [searchParams])

  return <S.Container>
    <S.Content>
      <S.Form action="" onSubmit={handleSubmit}>
        <InputText name="Busca..." onChange={(q: any) => setData(data => ({ ...data, q }))} value={data.q} />
        <S.Button>Buscar</S.Button>
      </S.Form>

      {state === PageState.LOADING && <>Carregando...</>}
      {state === PageState.NO_CONTENT && <>Não foi encontrado nenhum resultado para "{data.q}"</>}
      {state === PageState.READY &&
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data.posts.map(({ post_excerpt, ID, thumbnail, post_title, post_name }, i) => <NewsItem
          data-index={i}
          description={post_excerpt}
          key={ID}
          image={thumbnail}
          newsId={ID}
          title={post_title}
          name={post_name}
          visible={true}
        />)}
    </S.Content>
  </S.Container>
}
