import styled from 'styled-components'

export const Container = styled.h1`
  border-bottom: 2px solid #B3B2B2;
  font-size: 30pt;
  padding-inline: 60px;
  line-height: 60px;
  position: relative;
  white-space: nowrap;

  &::before {
    background: var(--primary);
    background: linear-gradient(to bottom, var(--secondary) 0%, var(--primary) 100%);
    background: -webkit-linear-gradient(top, var(--secondary) 0%, var(--primary) 100%);
    bottom: -3px;
    content: "";
    height: 4px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 70%;
  }

  @media (max-width: 764px) {
    font-size: 21pt;
    padding-inline: 40px;

    &::before {
      width: 76%;
    }
  }
`
