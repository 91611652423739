import styled from 'styled-components'

export const NavContainer = styled.nav`
  display: flex;

  ul {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;

    li {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
    }
  }

  @media (min-width: 1125px) {
    display: none;
  }
`
