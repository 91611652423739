import styled from 'styled-components'
import Accordion from '../../../../components/Accordion'
import { UnderlinedTitle } from '../styled'

export const BenefitsContainer = styled.div`
  align-items: start;
  display: flex;
  gap: 16px;
  padding: 0 28px 32px;
  justify-content: center;
  max-width: 1280px;
  width: 100%;

  > figure {
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  @media (max-width: 764px) {
    flex-direction: column-reverse;
    padding-top: 32px;

    > figure img {
      max-width: 60%;
    }
  }
`

export const BenefitsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  @media (max-width: 764px) {
    align-items: center;
  }
`

export const BenefitsTitle = styled(UnderlinedTitle)`
  color: var(--primary);
  font-size: 18pt;
  line-height: 40px;
  padding-inline: 32px;
  width: fit-content;

  &::before {
    width: 68%;
  }
`

export const BenefitsAccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 658px;
  width: 100%;
`

export const BenefitAccordion = styled(Accordion)`
  .content {
    padding: 0 20px 20px 46px;

    p, ul li {
      color: var(--text-secondary);
      font-size: 13.5pt;
      line-height: 18pt;

      strong {
        color: var(--primary);
        font-weight: 700;
      }
    }

    ul {
      list-style: disc;
      margin-left: 24px;
      width: fit-content;

      li + li {
        margin-top: 8px;
      }
    }

    @media (max-width: 764px) {
      p, ul li {
        font-size: 12pt;
      }

      ul {
        margin: 0;
      }
    }

    @media (max-width: 400px) {
      p, ul li {
        max-width: initial;
      }
    }
  }
`
