import { ReactElement, useEffect, useRef, useState } from 'react'
import CountUpProps from './props'

export default function CountUp ({ end, start = 0, trigger, ...rest }: CountUpProps): ReactElement {
  const [value, setValue] = useState<number>(0)
  const ref = useRef(start)

  const counter = end / 200

  const count = (): void => {
    if (ref.current < end) {
      const result = Math.ceil(ref.current + counter)

      if (result > end) {
        setValue(end)
        return
      }

      setValue(result)
      ref.current = result
    }

    setTimeout(count, 70)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (trigger === false) return

    let isMounted = true

    if (isMounted) count()

    return () => {
      isMounted = false
    }
  }, [end, trigger])

  return <span {...rest}>
    {value.toString().padStart(2, '0')}
  </span>
}
