import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageModel } from '../../data/entities/PageModel'
import { PageState } from '../../data/enums/PageState'
import { LoadBlogPost } from '../../domain/use-case/LoadBlogPost'
import NewsLink from '../Blog/NewsLink'
import * as S from './styled'

interface NewsProps {
  loadBlogPost: LoadBlogPost
}

export default function News ({ loadBlogPost }: NewsProps): ReactElement {
  const params = useParams()
  const [post, setPost] = useState<PageModel>({} as unknown as PageModel)
  const [states, setState] = useState<PageState>(PageState.LOADING)

  const previousPost: any = null
  const nextPost: any = null

  useEffect(() => {
    (async () => {
      setState(PageState.LOADING)
      const response = await loadBlogPost.handle({ slug: String(params.newTitle) })
      setPost(response)
      setState(PageState.READY)
    })()
  }, [])

  if (states === PageState.NO_CONTENT) return <></>

  return <S.Container>
    <S.Content>
      <S.NewsBanner>
        <img src={post.thumbnail} alt="" />
      </S.NewsBanner>

      <h1>{post.post_title}</h1>

      <S.NewsContent dangerouslySetInnerHTML={{ __html: post.post_content }} />

      <S.ButtonsContainer>
        {previousPost && <NewsLink
          newsId={previousPost.id}
          title={previousPost.title}
        >
          <button disabled={previousPost == null}>Post anterior</button>
        </NewsLink>}

        {nextPost && <NewsLink
          newsId={nextPost.id}
          title={nextPost.title}
        >
          <button disabled={nextPost == null}>Próximo post</button>
        </NewsLink>}
      </S.ButtonsContainer>
    </S.Content>

  </S.Container>
}
