import styled from 'styled-components'

export const Plates2Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;

  h1 {
    font-size: 30pt;
    font-weight: 800;
    margin-left: 8%;
    max-width: 764px;
  }

  @media (max-width: 1420px) {
    h1 {
      font-size: 27pt;
    }
  }

  @media (max-width: 764px) {
    h1 {
      font-size: 24pt;
      margin: 0;
      text-align: center;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 21pt;
    }
  }
`

export const Plates2Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  gap: 32px;
  padding-inline: 28px;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    figure {
      max-width: 64%;
    }
  }

  @media (max-width: 764px) {
    flex-direction: column;
  }
`

export const Plates2TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 640px;

  p, ul li {
    color: var(--text-secondary);
    font-size: 13.5pt;
    line-height: 18pt;

    strong {
      color: var(--primary);
      font-weight: 700;
    }
  }

  ul {
    list-style: disc;
    margin-left: 24px;
    width: fit-content;

    li + li {
      margin-top: 8px;
    }
  }

  @media (max-width: 764px) {
    max-width: 80%;

    p, ul li {
      font-size: 12pt;
    }
  }

  @media (max-width: 400px) {
    p, ul li {
      max-width: initial;
    }
  }
`
