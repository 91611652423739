import { ReactElement, useCallback } from 'react'
import NewsLink from '../NewsLink'
import NewsItemProps from './props'
import * as S from './styled'

export default function NewsItem ({ description, image, newsId, title, name, visible, ...rest }: NewsItemProps): ReactElement {
  const shortenDescription = useCallback((description: string) => {
    if (description === undefined) return ''

    return description.length < 364
      ? `${description.replace(/&lt;/g, '<').replace(/&gt;/g, '<')}`
      : `${description.replace(/&lt;/g, '<').replace(/&gt;/g, '<').substring(0, 364).trimEnd()}...`
  }, [])

  return <S.NewsItem $visible={visible} {...rest}>
    <S.NewsImage>
      <img src={image} alt="" />
    </S.NewsImage>

    <S.NewsContent>
      <h3>
        <NewsLink
          newsId={newsId}
          title={title}
        >
          {title}
        </NewsLink>
      </h3>

      <p>{shortenDescription(description)}</p>

      <NewsLink
        newsId={newsId}
        title={name}
      >
        <button>Leia mais</button>
      </NewsLink>
    </S.NewsContent>
  </S.NewsItem>
}
