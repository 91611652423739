import { Link } from 'react-router-dom'
import styled from 'styled-components'
import UT from '../../components/UnderlinedTitle'

export const Container = styled.main`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
`

export const UnderlinedTitle = styled(UT)`
  font-weight: 800;
  padding-inline: 52px;
  text-align: center;

  @media (max-width: 1180px) {
    font-size: 27pt;
  }

  @media (max-width: 950px) {
    font-size: 24pt;
    padding-inline: 40px;

    &::before {
      width: 76%;
    }
  }

  @media (max-width: 850px) {
    font-size: 21pt;
    line-height: 52px;
  }

  @media (max-width: 764px) {
    font-size: 18pt;
    padding-inline: 32px;

    &::before {
      width: 84%;
    }
  }

  @media (max-width: 620px) {
    line-height: normal;
    white-space: normal;
  }

  @media (max-width: 400px) {
    font-size: 15pt;
    padding-inline: 20px;

    &::before {
      width: 80%;
    }
  }
`

export const TopBannerBg = styled.div<{ $bannerBg: string }>`
  aspect-ratio: 1367 / 400;
  background-image: url(${({ $bannerBg }) => $bannerBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
`

export const TopBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin: 0.2% 6% 0;
  height: 100%;
  max-width: 1366px;

  h1 {
    color: var(--white);
    font-size: 30pt;
    font-weight: 800;
  }

  @media (max-width: 1270px) {
    h1 {
      font-size: 24pt;
    }
  }

  @media (max-width: 1170px) {
    h1 {
      font-size: 18.75pt;
    }
  }

  @media (max-width: 764px) {
    h1 {
      font-size: 18pt;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 15pt;
    }
  }
`

export const DescriptionContainer = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 54px 28px;
  max-width: 952px;

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    line-height: 18pt;
    text-align: center;

    strong {
      font-weight: 700;
    }

    @media (max-width: 764px) {
      font-size: 12pt;
    }
  }
`

export const SectionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
`

export const SolutionsSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1142px;
  padding-inline: 28px;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }
`

export const SolutionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 274px);
  gap: 16px;
  justify-content: space-around;
  place-items: center;
  width: 100%;
`

export const SolutionItem = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    color: var(--text-secondary);
    font-size: 15pt;
    font-weight: 700;
    text-transform: uppercase;
  }

  figure {
    max-width: fit-content;

    width: 100%;
  }
`

export const AttributesSection = styled.div`
  background-image: linear-gradient(to bottom, var(--white) 10%, var(--background) 10%, var(--background) 88%, var(--white) 88%);
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
  padding: 0 28px;
  position: relative;
  width: 100%;

  figure {
    max-width: 40%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 955px) {
    align-items: center;
    background-image: linear-gradient(to bottom, var(--white) 8%, var(--background) 8%);
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 28px 32px;

    figure {
      align-self: center;
      max-width: 480px;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 764px) {
    figure {
      max-width: 400px;
    }
  }
`

export const AttributesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1370px;
  padding-left: 64px;
  width: 60%;

  h1 {
    font-size: 30pt;
    font-weight: 800;
    max-width: 640px;
  }

  ul {
    list-style: none;
    max-width: 45%;

    li {
      align-items: center;
      color: var(--text-secondary);
      display: flex;
      font-size: 15pt;
      font-weight: 700;
      margin-top: 16px;
      white-space: nowrap;

      img {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 1420px) {
    h1 {
      font-size: 27pt;
    }

    ul li {
      font-size: 13.5pt;

      img {
        width: 56px;
      }
    }
  }

  @media (max-width: 1270px) {
    h1 {
      font-size: 24pt;
    }

    ul li {
      font-size: 12pt;

      img {
        width: 48px;
      }
    }
  }

  @media (max-width: 1170px) {
    h1 {
      font-size: 18.75pt;
    }

    ul {
      margin-top: 8px;
      max-width: initial;

      li {
        font-size: 10.5pt;
        margin-top: 8px;

        img {
          margin-right: 8px;
          width: 48px;
        }
      }
    }
  }

  @media (max-width: 954px) {
    padding: 0;
    width: initial;

    h1 {
      font-size: 24pt;
      text-align: center;
    }

    ul {
      margin-top: 0;
      max-width: initial;

      li {
        font-size: 12pt;
        margin-top: 16px;
        white-space: initial;

        img {
          width: 56px;
        }
      }
    }
  }

  @media (max-width: 764px) {
    h1 {
      font-size: 18.75pt;
    }

    ul {
      margin-top: 8px;

      li {
        font-size: 10.5pt;
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 460px) {
    h1 {
      font-size: 15pt;
    }

    ul {
      margin-top: 4px;

      li {
        font-size: 9pt;
        margin-top: 4px;

        img {
          width: 36px;
        }
      }
    }
  }
`

export const BenefitsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  max-width: 1370px;
  padding: 20px 64px;
  width: 100%;
`

export const BenefitsTitleContainer = styled.div`
  align-self: center;
  position: relative;

  &::before {
    background: #B3B2B2;
    bottom: -3px;
    content: "";
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 68%;
  }
`

export const BenefitsTitle = styled(UnderlinedTitle)`
  border-bottom: none;
  padding-inline: 0;
  width: fit-content;

  &::before {
    bottom: -4px;
    width: 54%;
  }

  @media (max-width: 764px) {
    padding-inline: 32px;

    &::before {
      width: 48%;
    }
  }

  @media (max-width: 400px) {
    padding-inline: 20px;

    &::before {
      width: 40%;
    }
  }
`

export const BenefitsContent = styled.div`
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 16px;
  justify-content: space-between;
  max-width: 1126px;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    max-width: 464px;
  }
`

export const Benefit = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  p {
    color: var(--text-secondary);
    font-size: 15pt;
  }

  @media (max-width: 1170px) {
    img {
      max-width: 72px;
    }

    p {
      font-size: 12pt;
    }
  }

  @media (max-width: 580px) {
    img {
      max-width: 64px;
    }

    p {
      font-size: 10.5pt;
    }
  }

  @media (max-width: 460px) {
    img {
      max-width: 52px;
    }

    p {
      font-size: 9.5pt;
    }
  }
`

export const ConclusionSection = styled.div`
  align-items: center;
  background-color: #EFEEEE;
  display: flex;
  gap: 32px;
  justify-content: space-around;
  padding: 20px 28px 20px 0;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      aspect-ratio: 623 / 476;
      max-width: 624px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 100%;
    }
  }

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    line-height: 18pt;
    max-width: 586px;

    strong {
      color: var(--primary);
      font-weight: 800;
    }

    @media (max-width: 1024px) {
      font-size: 12pt;
    }


    @media (max-width: 764px) {
      font-size: 12pt;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    padding-inline: 28px;

    figure {
      min-width: initial;
      max-width: 532px;
    }
  }
`
