import { Fragment, ReactElement, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ReactComponent as ArrowHover } from '../../assets/arrow-colored.svg'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import NavItemWithSubMenuProps from './props'
import * as S from './styled'

export default function NavItem ({ label, to, submenuItems }: NavItemWithSubMenuProps): ReactElement {
  const { pathname } = useLocation()
  const [activeItem, setActiveItem] = useState('')
  const [activeTab, setActiveTab] = useState('')

  const activate = useCallback((path: string): boolean => pathname.includes(path), [pathname])

  return <li onMouseEnter={() => setActiveTab(label)} onMouseLeave={() => setActiveTab('')}>
    <S.NavLink href={to} $active={activate(to)}>
      {label}
    </S.NavLink>

    {(activeTab === label) && (submenuItems != null) && <S.NavInnerItemsContainer>
      {submenuItems.map((submenuItem, index) => <Fragment key={index}>
        <S.NavInnerItem
          onMouseEnter={() => setActiveItem(submenuItem.label)}
          onMouseLeave={() => setActiveItem('')}
        >
          {submenuItem.subItems != null
            ? <>
              <p>{submenuItem.label}</p>
              {(activeItem === submenuItem.label ? <ArrowHover /> : <Arrow />)}

              {activeItem === submenuItem.label && <S.NavInnerSubItemsContainer>
                {submenuItem.subItems.map((subItem, subIndex, subItems) => <Fragment key={subIndex}>
                  <S.NavInnerSubItem to={subItem.to}>
                    {subItem.label}
                  </S.NavInnerSubItem>

                  {subIndex < (subItems.length - 1) && <hr />}
                </Fragment>
                )}
              </S.NavInnerSubItemsContainer>}
            </>
            : <a href={submenuItem.to}>{submenuItem.label}</a>
          }
        </S.NavInnerItem>

        {index < (submenuItems.length - 1) && <hr />}
      </Fragment>
      )}
    </S.NavInnerItemsContainer>}
  </li>
}
