import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1280px;
  width: 100%;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }
`

export const DownloadButton = styled.a`
  button {
    background-color: #FFBA09;
    border: none;
    border-radius: 12px;
    color: var(--white);
    cursor: pointer;
    font-size: 15pt;
    font-weight: 700;
    padding: 14px 20px;
  }
`

export const ProductsContainer = styled.div<{ $isTransitioning: boolean }>`
  opacity: ${({ $isTransitioning }) => ($isTransitioning ? 0 : 1)};
  transition: opacity 0.3s ease;
  width: 100%;
`

export const Product = styled(Link)`
  transition: all .6s linear;
`

export const OverlayContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  &:hover {
    figure img {
      scale: 1.1;
      transition: all .6s linear;
    }
  }
`

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(16, 50, 114, .6);
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.32);
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: opacity .8s ease;

  &:hover {
    opacity: 1;
  }
`

export const IconContainer = styled.div`
  align-items: center;
  background: linear-gradient(45deg, var(--secondary), var(--primary));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 32px;
  transition: all .3s linear;

  i {
    color: var(--white);
  }

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.32);
    scale: 1.1;
  }
`
