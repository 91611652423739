import styled from 'styled-components'
import UT from '../../components/UnderlinedTitle'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
`

export const TopBannerBg = styled.div<{ $background: string }>`
  aspect-ratio: 1367 / 400;
  background: linear-gradient(270deg, rgba(58, 58, 58, 0.7) 11.85%, rgba(0, 0, 0, 0.329) 50%), url(${props => props.$background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;

  @media (max-width: 1024px) {
    aspect-ratio: initial;
  }
`

export const TopBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  padding: 64px;
  height: 100%;
  max-width: 1366px;

  p {
    color: var(--white);
    font-size: 15pt;
    font-weight: 600;
    max-width: 570px;
  }

  @media (max-width: 764px) {
    padding: 28px 32px;

    p {
      font-size: 12pt;
    }
  }
`

export const UnderlinedTitle = styled(UT)`
  font-size: 27pt;
  font-weight: 800;
  line-height: 72px;
  text-align: center;

  @media (max-width: 1070px) {
    font-size: 21pt;
    padding-inline: 32px;
    line-height: 40px;
    white-space: normal;

    &::before {
      width: 84%;
    }
  }

  @media (max-width: 650px) {
    font-size: 18pt;
    padding-inline: 20px;

    &::before {
      width: 80%;
    }
  }
`

export const DistributorContainer = styled.div<{ $background: string }>`
  aspect-ratio: 1370 / 513;
  background-image: url(${props => props.$background});
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  @media (min-width: 803px) {
    max-height: 513px;

    figure {
      display: none;
    }
  }

  @media (max-width: 803px) {
    background-image: none;
    flex-direction: column-reverse;
    justify-content: center;

    figure {
      align-self: flex-end;
      max-width: 366px;

      img {
        width: 100%;
      }
    }
  }
`

export const DistributorContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1370px;
  padding: 20px 64px;

  div {
    max-width: 45%;

    h1 {
      font-size: 26.25pt;
      font-weight: 800;
      max-width: 570px;
    }

    p {
      font-size: 15pt;
    }

    @media (max-width: 1070px) {
      h1 {
        font-size: 18.75pt;
      }

      p {
        font-size: 12pt;
      }
    }

    @media (max-width: 802px) {
      max-width: fit-content;
    }
  }

  @media (max-width: 764px) {
    padding: 0 28px;

    div h1 {
      max-width: 400px;
    }
  }
`

export const CreativityContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 28px;
  width: 100%;

  h1, p {
    max-width: 993px;
  }

  h1 {
    padding-inline: 0;

    &::before {
      width: 66%;
    }
  }

  p {
    color: #626060;
    font-size: 15pt;
    text-align: center;
  }
`

export const HowItWorksContainer = styled.div`
  align-items: center;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  padding: 42px 28px;
  width: 100%;
`

export const TitleContainer = styled.div`
  background: linear-gradient(266.44deg, #103272 47.08%, #0077B6 55.06%);
  border-radius: 68px;
  padding-inline: 52px;

  h1 {
    color: var(--white);
    font-size: 26.25pt;
    font-weight: 800;
  }

  @media (max-width: 1070px) {
    padding-inline: 32px;

    h1 {
      font-size: 18.75pt;
    }
  }
`

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1156px;
  width: 100%;
`

export const Steps = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  justify-content: space-between;

  h3 {
    color: var(--primary);
    font-size: 15pt;
    font-weight: 800;
  }

  p {
    font-size: 15pt;
  }

  @media (max-width: 1070px) {
    h3, p {
      font-size: 12pt;
    }
  }

  @media (max-width: 700px) {
    gap: 16px;
    grid-template-columns: 1fr;
  }
`

export const Footnote = styled.div`
  p {
    color: #626060;
    font-size: 22.5pt;
    font-weight: 800;
  }

  strong {
    color: #0077B6;
    font-weight: 800;
  }

  @media (max-width: 1070px) {
    font-size: 18pt;
  }

  @media (max-width: 700px) {
    font-size: 15pt;
  }
`

export const ApplicationsContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(to bottom, var(--background) 48%, var(--white) 48%);
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding-bottom: 78px;
  width: 100%;

  div {
    display: flex;
    gap: 14px;
    padding-inline: 28px;

    figure {
      max-width: fit-content;

      img {
        width: 100%;
      }
    }

    @media (max-width: 700px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

export const ThinUnderlinedTitle = styled(UT)`
  font-weight: 300;
  padding-inline: 152px;

  @media (max-width: 1070px) {
    padding-inline: 52px;

    &::before {
      width: 72%;
    }
  }
`

export const EquipmentsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-inline: 28px;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 42px;
  justify-content: center;
  padding: 26px 28px 80px;
  width: 100%;

  button {
    background-color: #FFBA09;
    border: none;
    border-radius: 33.5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    height: 48px;
    max-width: 348px;
    padding-inline: 16px;
    outline: none;
    white-space: nowrap;
    width: 100%;

    span {
      color: var(--white);
      font-size: 18.75pt;
      font-weight: 700;
      text-shadow: 4px 4px #00000040;

      @media (max-width: 1070px) {
        font-size: 15pt;
      }

      @media (max-width: 764px) {
        font-size: 12pt;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const ProductsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1280px;
  padding: 0 28px 88px;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }
`
