import styled from 'styled-components'

export const Container = styled.header`
  background-color: var(--white);
  width: 100%;
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 32px 48px 0;
  width: 100%;

  button {
    align-items: center;
    background-color: #FFBA09;
    border: none;
    border-radius: 33.5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    display: flex;
    height: 44px;
    justify-content: center;
    gap: 8px;
    max-width: 348px;
    padding-inline: 16px;
    outline: none;
    white-space: nowrap;
    width: fit-content;

    span {
      color: var(--white);
      font-size: 13.5pt;
      font-weight: 700;
      text-shadow: 3px 3px #00000040;
    }

    img {
      margin-top: 8px;
    }
  }

  @media (max-width: 1125px) {
    padding-bottom: 16px;
  }

  @media (max-width: 1070px) {
    padding-left: 36px;

    button {
      height: 40px;

      span {
        font-size: 12pt;
      }
    }
  }

  @media (max-width: 764px) {
    padding-right: 32px;

    button {
      height: 36px;

      span {
        font-size: 10pt;
      }
    }
  }

  @media (max-width: 500px) {
    justify-content: center;
    padding-inline: 28px;

    button {
      height: 32px;

      span {
        font-size: 9pt;
      }
    }
  }
`

export const NavDesktopContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 1125px) {
    &.desktop-nav {
      display: none;
    }
  }
`

export const LogoContainer = styled.a`
  max-width: 150px;
  place-self: center;

  img {
    width: 100%;
  }
`

export const OptionsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 20px 0;

  p {
    color: var(--primary);
    font-size: 13.5pt;
  }

  a {
    cursor: pointer;

    img {
      max-width: 24px;
    }
  }

  &.desktop-nav {
    p {
      border-radius: 16px;
      padding: 4px 8px;
      transition: all .5s ease-in-out;

      &:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        transform: translateX(-8%);
        scale: 1;
      }
    }
  }

  @media (max-width: 1125px) {
    justify-content: space-between;

    p {
      font-size: 13.5pt;
    }

    a {
      img {
        max-width: 20px;
      }
    }

    div {
      display: flex;
      gap: 16px;
    }
  }
`

export const NavMobileContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 1124px) {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding-inline: 20px;
  }
`

export const MobileMenuToggle = styled.button`
  background-color: transparent;
  border: none;
  padding: 8px;
  outline: none;
  width: fit-content;

  > div {
    background-color: #384141;
    height: 2px;
    margin: 4px 0;
    width: 24px;
  }

  @media (min-width: 1125px) {
    display: none;
  }
`

export const MobileMenuContainer = styled.div<{ $menuOpen: boolean }>`
  background-color: var(--white);
  height: 100%;
  left: 0;
  max-width: 342px;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: ease-in-out 0.5s;
  width: ${({ $menuOpen }) => $menuOpen ? '76%' : 0};
  z-index: 10;
`

export const MobileMenuContent = styled.div`
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100%;
  padding: 28px 24px;
  width: 100%;
`

export const MobileCloseButton = styled.button`
  align-self: flex-start;
  background-color: transparent;
  border: none;
  font-size: 24px;
  justify-self: flex-end;
  padding: 8px;
  outline: none;
  width: fit-content;
`
