import { ReactElement, useMemo } from 'react'
import Gallery from '../../../components/Gallery'
import { PageState } from '../../../data/enums/PageState'
import useLoadPageBySlug from '../../../hooks/useLoadPageBySlug'
import Attributes from './Attributes'
import Benefits from './Benefits'
import CompanyBenefits from './CompanyBenefits'
import Intro from './Intro'
import * as S from './styled'

export default function Pallet (): ReactElement {
  const { data, state } = useLoadPageBySlug<any>('solucoes-para-negocios/manga-pallet')

  const images = useMemo(() => {
    if (!data) return []
    if (!data.fields) return []
    if (!data.fields.galeria) return []
    return data.fields.galeria.map(({ imagem }: { imagem: string }) => imagem)
  }, [data])

  if (state === PageState.LOADING) return <>Carregando...</>
  if (state === PageState.NO_CONTENT) return <>Nenhum conteúdo disponivel</>

  return <S.Container>
    <Intro data={data} />

    <Benefits data={data} />

    <Attributes data={data} />

    <CompanyBenefits data={data} />

    <Gallery images={images} />
  </S.Container>
}
