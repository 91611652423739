import axios, { isAxiosError } from 'axios'
import { LoadProductById } from '../../domain/use-case/LoadProductById'

export class ApiLoadProductById implements LoadProductById {
  async handle (params: LoadProductById.Params): Promise<LoadProductById.Result> {
    try {
      const response = await axios.get(`${String(process.env.REACT_APP_BASE_URL)}/wp-json/plb2024/v1/product/${params.productId}`)
      return response
    } catch (error) {
      if (!isAxiosError(error)) throw error
      if (!error.response) throw error
      return error.response
    }
  }
}
