import { ReactElement } from 'react'
import PlatesProps from '../props'
import * as S from './styled'

export default function Differentials ({ data }: PlatesProps): ReactElement {
  return <S.Container>
    <figure>
      <img src={data.fields.diferenciais.imagem} alt="" />
    </figure>

    <S.Content>
      <h1>{data.fields.diferenciais.titulo}</h1>

      <ul>
        {data.fields.diferenciais.conteudo.map((item: { icone: string, texto: string }) => <li key={item.texto}>
          <img src={item.icone} alt="" />

          {item.texto}
        </li>)}
      </ul>
    </S.Content>
  </S.Container>
}
