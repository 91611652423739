import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;

  .container {
    gap: 24px 48px;
    grid-template-columns: repeat(auto-fit, 352px);

    @media (max-width: 450px) {
      grid-template-columns: 1fr;
    }
  }
`
