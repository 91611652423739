import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 32px 28px;
`
