import { ReactElement, useMemo } from 'react'
import Gallery from '../../../components/Gallery'
import { PageState } from '../../../data/enums/PageState'
import useLoadPageBySlug from '../../../hooks/useLoadPageBySlug'
import Advantages from './Advantages'
import Benefits from './Benefits'
import PlasticLayers from './PlasticLayers'
import * as S from './styled'

export default function SeparatorSpreadsheets (): ReactElement {
  const { data, state } = useLoadPageBySlug<any>('solucoes-para-negocios/folha-separadora')

  const images = useMemo(() => {
    if (!data) return []
    if (!data.fields) return []
    if (!data.fields.galeria) return []
    return data.fields.galeria.map(({ imagem }: { imagem: string }) => imagem)
  }, [data])

  if (state === PageState.LOADING) return <>Carregando...</>
  if (state === PageState.NO_CONTENT) return <>Nenhum conteúdo disponivel</>

  return <S.Container>
    <PlasticLayers data={data} />

    <Advantages data={data} />

    <Gallery images={images} maxWidth={600} />

    <Benefits data={data} />
  </S.Container>
}
