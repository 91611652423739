import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    font-size: 13.5pt;
    font-weight: 400;

    span {
      color: var(--primary);
      font-weight: 600;
    }
  }

  @media (max-width: 1180px) {
    p {
      font-size: 12pt;
    }
  }

  @media (max-width: 764px) {
    p {
      font-size: 10.5pt;
    }
  }
`
