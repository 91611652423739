import { EnviromentModel } from '../entities/EnviromentModel'

export const mapToEnvironment = (enviroment: any): EnviromentModel => {
  return {
    descricao: enviroment.descricao,
    fotoDeFundo: enviroment.foto_de_fundo,
    reciclagem: enviroment.reciclagem,
    titulo: enviroment.titulo
  }
}
