import styled from 'styled-components'

export const Container = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const TitleContainer = styled.div`
  align-items: center;
  box-shadow: -2px 4px 7px 3px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  display: flex;
  gap: 16px;
  height: 56px;
  justify-content: space-between;
  padding-inline: 40px 20px;

  @media (max-width: 1024px) {
    padding-left: 32px;
  }

  @media (max-width: 600px) {
    padding-left: 20px;
  }
`

export const Title = styled.p`
  color: var(--primary);
  font-size: 15pt;
  font-weight: 800;
  max-width: 88%;

  @media (max-width: 1024px) {
    font-size: 13.5pt;
  }

  @media (max-width: 600px) {
    font-size: 10.5pt;
  }
`

export const Icon = styled.figure<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => $isOpen && 'rotate(180deg)'};
  margin-top: 4px;
  max-width: 28px;

  img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    max-width: 24px;
  }

  @media (max-width: 600px) {
    max-width: 20px;
  }
`

export const Content = styled.div`
  padding: 0 20px 28px 20px;
`
