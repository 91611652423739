import styled from 'styled-components'
import UT from '../../../../components/UnderlinedTitle'

export const Container = styled.div`
  align-items: start;
  display: flex;
  gap: 16px;
  padding-bottom: 32px;
  justify-content: center;
  width: 100%;

  > figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }

  @media (max-width: 764px) {
    padding-top: 32px;

    > figure img {
      width: 60%;
    }
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1148px;
  width: 100%;
`

export const UnderlinedTitle = styled(UT)`
  color: var(--primary);
  font-size: 24pt;
  font-weight: 800;
  padding-inline: 0;
  text-align: center;
  width: fit-content;

  &::before {
    width: 64%;
  }

  @media (max-width: 580px) {
    line-height: normal;
    white-space: normal;
  }

  @media (max-width: 400px) {
    font-size: 15pt;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  h3 {
    color: var(--primary);
    font-size: 18pt;
    font-weight: 700;
    line-height: 24pt;
  }

  @media (max-width: 764px) {
    h3 {
      font-size: 15pt;
    }
  }
`

export const ItemContent = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: space-around;
  width: 100%;

  figure {
    max-width: fit-content;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &.maintenance {
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    figure {
      max-width: 40%;
    }
  }

  @media (max-width: 764px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  p {
    font-size: 15pt;
    line-height: 27pt;

    strong {
      color: var(--primary);
    }
  }

  br {
    content: "";
    display: block;
    margin: 0;
  }

  @media (max-width: 764px) {
    p {
      font-size: 12pt;
    }
  }
`
