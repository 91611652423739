import { ReactElement, useCallback, useState } from 'react'
import Accordion from '../../../components/Accordion'
import { PageState } from '../../../data/enums/PageState'
import useLoadPageBySlug from '../../../hooks/useLoadPageBySlug'
import * as S from './styled'

export default function Storage (): ReactElement {
  const { data, state } = useLoadPageBySlug<any>('solucoes-para-negocios/armazenagem')
  const [activeAccordion, setActiveAccordion] = useState<number>()

  const handleOnOpen = useCallback((newIndex?: number): void => setActiveAccordion(newIndex), [])

  if (state === PageState.LOADING) return <>Carregando...</>
  if (state === PageState.NO_CONTENT) return <>Nenhum conteúdo disponivel</>

  return <S.Container>
    <S.TransportContainer>
      <S.TransportContent>
        <h1>Caixas de Transporte</h1>

        <div dangerouslySetInnerHTML={{ __html: data.fields.caixas_de_transporte.conteudo }}></div>
      </S.TransportContent>

      <figure>
        <img src={data.fields.caixas_de_transporte.imagem} alt="" />
      </figure>
    </S.TransportContainer>

    <S.BenefitsSection>
      <S.BenefitsContent>
        <figure>
          <img src={data.fields.sanfona.imagem} alt="" />
        </figure>

        <S.BenefitsAccordionsContainer>
          {data.fields.sanfona.items.map(({ conteudo, titulo }: any, index: number) => <Accordion
            key={index}
            index={index}
            isOpen={activeAccordion === index}
            onOpen={handleOnOpen}
            title={titulo}
          >
            <p dangerouslySetInnerHTML={{ __html: conteudo.replace(/\n/g, '<br />') }} />
          </Accordion>)}
        </S.BenefitsAccordionsContainer>
      </S.BenefitsContent>
    </S.BenefitsSection>

    <S.AttributesSection>
      <figure>
        <img src={data.fields.projetos.imagem} alt="" />
      </figure>

      <S.AttributesContent dangerouslySetInnerHTML={{ __html: data.fields.projetos.conteudo }}></S.AttributesContent>
    </S.AttributesSection>

    <S.NylonContainer>
      <S.NylonContent>
        <figure>
          <img src={data.fields.empilhaveis.imagem} alt="" />
        </figure>

        <h1>{data.fields.empilhaveis.titulo}</h1>

        <div>
          <div dangerouslySetInnerHTML={{ __html: data.fields.empilhaveis.conteudo }}></div>

          <figure>
            <img src={data.fields.empilhaveis.imagem} alt="" />
          </figure>
        </div>
      </S.NylonContent>

    </S.NylonContainer>
  </S.Container>
}
