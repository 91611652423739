import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Active { $active: boolean }

export const NavLink = styled(Link)<Active>`
  color: #888;
  font-size: 15pt;
  padding-bottom: 2px;
  text-decoration-line: ${({ $active }) => $active && 'underline'};
  text-decoration-thickness: 4px;
  text-underline-offset: 6px;
  width: fit-content;
`

export const NavInnerItemsContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  width: 100%;

  hr {
    width: 90%;
  }
`

export const NavInnerItem = styled.div<Active>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: fit-content;
  padding: 8px;
  width: 100%;

  p, > a {
    color: ${({ $active }) => $active ? 'var(--primary)' : 'var(--text-primary)'};
    font-size: 10.5pt;
  }
`

export const NavInnerSubItemsContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(to right, transparent 8px, var(--white) 8px);
  display: flex;
  flex-direction: column;
  width: fit-content;

  hr {
    margin-left: 8px;
    width: 80%;
  }
`

export const NavInnerSubItem = styled(Link)<Active>`
  align-items: center;
  color: ${({ $active }) => $active ? 'var(--primary)' : 'var(--text-primary)'};
  font-size: 10.5pt;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 32px 8px 16px;
  width: 100%;
  z-index: 1;

  &:hover {
    color: var(--primary);
  }
`
