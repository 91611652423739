import { AxiosInstance } from 'axios'
import { LoadBlogPost } from '../../domain/use-case/LoadBlogPost'

export class ApiLoadBlogPost implements LoadBlogPost {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadBlogPost.Params): Promise<LoadBlogPost.Result> {
    const response = await this.api.get(`/wp-json/plb2024/v1/blog/${String(params.slug)}`)
    switch (response.status) {
      case 200:
        return response.data
      case 204:
        throw new Error('Sem conteudo')
      default:
        throw new Error('Erro interno do servidor')
    }
  }
}
