import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  min-height: 340px;
  width: 100%;
`

export const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 340px);
  grid-auto-rows: 340px;
  gap: 16px;
  place-content: center;
  transition: opacity 0.3s ease;
  width: 100%;

  @media (max-width: 410px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }
`

export const Product = styled.a`
  transition: all .6s linear;

  figure {
    max-width: fit-content;

    img {
      aspect-ratio: 1;
      object-fit: contain;
      width: 100%;
    }
  }
`
export const ProductLink = styled(Link)`
  transition: all .6s linear;

  figure {
    max-width: fit-content;

    img {
      aspect-ratio: 1;
      object-fit: contain;
      width: 100%;
    }
  }
`

export const OverlayContainer = styled.div`
  aspect-ratio: 1;
  background-color: var(--white);
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  &:hover {
    figure img {
      scale: 1.1;
      transition: all .6s linear;
    }
  }
`

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(16, 50, 114, .6);
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.32);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: opacity .8s ease;

  &:hover {
    opacity: 1;
  }

  p {
    color: var(--white);
    font-weight: 700;
    max-width: 90%;
    text-align: center;
  }
`

export const IconContainer = styled.div`
  align-items: center;
  background: linear-gradient(45deg, var(--secondary), var(--primary));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 32px;
  transition: all .3s linear;

  i {
    color: var(--white);
  }

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.32);
    scale: 1.1;
  }
`

export const ShowMoreButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--primary);
  border-radius: 40px;
  color: var(--text-title);
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  font-size: 12pt;
  font-weight: 700;
  opacity: ${({ disabled }) => disabled && 0.6};
  padding: 2px 12px;
  transition: all .4s linear;
  width: fit-content;

  &:hover {
    background-color: ${({ disabled }) => !disabled && 'var(--primary)'};
    color: ${({ disabled }) => !disabled && 'var(--white)'};
  }

  @media (max-width: 764px) {
    font-size: 10pt;
    padding: 4px 8px;
  }
`
