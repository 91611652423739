import { ReactElement } from 'react'
import PalletProps from '../props'
import * as S from './styled'

export default function CompanyBenefits ({ data }: PalletProps): ReactElement {
  return <S.Container>
    <S.UnderlinedTitle>{data.fields.beneficios_para_sua_empresa.titulo}</S.UnderlinedTitle>

    <S.Content>
      {data.fields.beneficios_para_sua_empresa.items.map((item: { imagem: string, titulo: string }) => <S.Item icon={item.imagem} text={item.titulo} />)}
    </S.Content>
  </S.Container>
}
