import { ReactElement } from 'react'
import { ReactComponent as Check } from '../assets/check.svg'
import PlatesProps from '../props'
import { UnderlinedTitle } from '../styled'
import * as S from './styled'

export default function Applications ({ data }: PlatesProps): ReactElement {
  return <S.ApplicationsContainer>
    <UnderlinedTitle>Aplicações diversas</UnderlinedTitle>

    <S.ApplicationsContent>
      {data.fields.aplicacoes.map((item: { texto: string }) => <S.ApplicationItem key={item.texto}>
        <p>{item.texto}</p>

        <Check />
      </S.ApplicationItem>)}
    </S.ApplicationsContent>
  </S.ApplicationsContainer>
}
