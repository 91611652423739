import { ReactElement, useCallback, useState } from 'react'
import PalletProps from '../props'
import * as S from './styled'

export default function Benefits ({ data }: PalletProps): ReactElement {
  const [activeAccordion, setActiveAccordion] = useState<number>()

  const handleOnOpen = useCallback((newIndex?: number): void => setActiveAccordion(newIndex), [activeAccordion])

  return <S.BenefitsContainer>
    <S.BenefitsContent>
      <S.BenefitsTitle>{data.fields.beneficios.titulo}</S.BenefitsTitle>

      <S.BenefitsAccordionsContainer>
        {data.fields.beneficios.items.map((item: { conteudo: string, titulo: string }, i: number) => <S.BenefitAccordion
          index={i}
          isOpen={activeAccordion === i}
          onOpen={handleOnOpen}
          title={item.titulo}
        >
          <div dangerouslySetInnerHTML={{ __html: item.conteudo }}></div>
        </S.BenefitAccordion>)}
      </S.BenefitsAccordionsContainer>
    </S.BenefitsContent>
  </S.BenefitsContainer>
}
