import { AxiosInstance } from 'axios'
import { LoadSearch } from '../../domain/use-case/LoadSearch'

export class ApiLoadSearch implements LoadSearch {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadSearch.Params): Promise<LoadSearch.Result> {
    const response = await this.api.get('/wp-json/plb2024/v1/search', { params: { q: params.content } })
    switch (response.status) {
      case 200:
        return response.data
      case 204:
        throw new Error('Sem conteudo')
      default:
        throw new Error('Erro interno do servidor')
    }
  }
}
