import { ReactElement, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MenuItemModel } from '../../data/entities/MenuItemModel'
import { useClickOutside } from '../../hooks/useClickOutside'
import MobileNavList from './MobileNavList'
import NavList from './NavList'
import cart from './assets/CARRINHO.svg'
import logo from './assets/logo.png'
import search from './assets/search.svg'
import * as S from './styled'

interface HeaderProps {
  menu: MenuItemModel[]
  phone: string
}

export default function Header ({ menu, phone }: HeaderProps): ReactElement {
  const { pathname } = useLocation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [wrapperRef] = useClickOutside(() => setMenuOpen(false))

  return <S.Container ref={wrapperRef}>
    {(pathname.includes('papelaria') || pathname.includes('cricut')) && <S.ButtonsContainer className="desktop-nav">
      {!pathname.includes('cricut') && <Link to="https://www.lojapolibras.com.br/" target="_blank" rel="noreferrer">
        <button>
          <span>
            Loja
          </span>
          <img src={cart} alt="Carrinho" />
        </button>
      </Link>}

      <Link to="http://polibras.upwaycrm.com/login/doformlogin" target="_blank" rel="noreferrer">
        <button>
          <span>
            Área do Representante
          </span>
        </button>
      </Link>
    </S.ButtonsContainer>}

    <S.NavDesktopContainer className="desktop-nav">
      <S.LogoContainer href="/">
        <figure>
          <img src={logo} alt="Logo Polibras" />
        </figure>
      </S.LogoContainer>

      <NavList menu={menu} />

      <S.OptionsContainer className="desktop-nav">
        <p><a href={`tel: ${phone.replace(/\D/, '')}`}>{phone}</a></p>

        <Link to="busca">
          <img src={search} alt="Pesquisar" />
        </Link>
      </S.OptionsContainer>
    </S.NavDesktopContainer>

    <S.NavMobileContainer>
      <S.MobileMenuToggle onClick={() => setMenuOpen(!menuOpen)}>
        <div />
        <div />
        <div />
      </S.MobileMenuToggle>

      <S.LogoContainer href="/">
        <figure>
          <img src={logo} alt="Logo Polibras" />
        </figure>
      </S.LogoContainer>

      <S.MobileMenuContainer $menuOpen={menuOpen}>
        <S.MobileMenuContent>
          <S.MobileCloseButton onClick={() => setMenuOpen(!menuOpen)}>
            x
          </S.MobileCloseButton>

          <NavList menu={menu} />

          <MobileNavList handleToggleMenu={() => setMenuOpen(!menuOpen)} />

          <S.OptionsContainer>
            <p><a href={`tel: ${phone.replace(/\D/, '')}`}>{phone}</a></p>

            <div>
              <Link to="busca">
                <img src={search} alt="Pesquisar" />
              </Link>
            </div>
          </S.OptionsContainer>
        </S.MobileMenuContent>
      </S.MobileMenuContainer>
    </S.NavMobileContainer>
  </S.Container>
}
