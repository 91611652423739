import styled from 'styled-components'

export const NewsItem = styled.article<{ $visible: boolean }>`
  display: flex;
  gap: 44px;
  opacity: ${({ $visible }) => $visible ? 1 : 0};
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 764px) {
    flex-direction: column;
    max-width: 410px;
  }
`

export const NewsImage = styled.figure`
  align-self: center;
  aspect-ratio: 1;
  border-radius: 16px;
  display: flex;
  flex: 1;
  height: 100%;
  max-height: 280px;
  max-width: 280px;
  overflow: hidden;
  width: 100%;

  img {
    transition: all .8s linear;
    width: 100%;

    &:hover {
      scale: 1.1;
    }
  }
`

export const NewsContent = styled.div`
  aspect-ratio: 652 / 280;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  max-width: 652px;
  width: 100%;

  h3 {
    font-size: 21pt;
    font-weight: 200;
    padding-right: 28px;
  }

  p {
    color: #a5a5a5;
    font-size: 10.5pt;
  }

  a {
    width: fit-content;

    button {
      background: linear-gradient(45deg, var(--secondary), var(--primary));
      border: none;
      border-radius: 36px;
      color: var(--white);
      cursor: pointer;
      font-size: 10.5pt;
      font-weight: 700;
      padding: 12px 40px;
      text-transform: uppercase;
      width: fit-content;
    }
  }

  @media (max-width: 1024px) {
    h3 {
      font-size: 18pt;
    }

    p, a button {
      font-size: 9pt;
    }

    a button {
      padding-inline: 32px;
    }
  }

  @media (max-width: 764px) {
    aspect-ratio: initial;

    h3 {
      padding: 0;
    }
  }
`
