import { ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useParams } from 'react-router-dom'
import { ModalConfig, useModal } from '../../../contexts/ModalContext'
import { ApiLoadProductById } from '../../../data/use-case/ApiLoadProductById'
import ApiErrorMessage from '../../ApiErrorMessage'
import Loading from '../../Loading'
import arrowBack from './assets/arrowBack.png'
import arrowForward from './assets/arrowForward.png'
import { ReactComponent as Magnifier } from './assets/search.svg'
import * as S from './styled'

const loadProductById = new ApiLoadProductById()

enum Status {
  LOADING,
  READY,
  ERROR
}

interface ResponseData {
  product: {
    post_title: string
    post_content: string
  }
  fields: {
    galeria: Array<{
      imagem: string
    }>
  }
}

export default function Product (): ReactElement {
  const { closeModal, modalIsOpen, openModal, updateModalBody } = useModal()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [data, setData] = useState<ResponseData>({} as unknown as ResponseData)
  const [status, setStatus] = useState<Status>(Status.LOADING)
  const { id } = useParams() as { id: string }

  const modalConfig: ModalConfig = useMemo(() => ({
    content: {
      maxWidth: 764,
      overflow: 'hidden'
    },
    overlay: {
      cursor: 'zoom-out',
      zIndex: 1
    },
    shape: 'square'
  }), [])

  const handleOpenModal = useCallback((index: number): void => {
    setCurrentIndex(index)

    openModal(modalConfig)
  }, [openModal])

  useLayoutEffect(() => {
    if (data.fields?.galeria && modalIsOpen) {
      const body = <S.ModalZoom>
        <img src={data.fields.galeria[currentIndex].imagem} alt="" onClick={closeModal} />

        {currentIndex > 0 && <S.ArrowBack onClick={() => setCurrentIndex((prevIndex) => prevIndex - 1)}>
          <img src={arrowBack} alt="Previous" />
        </S.ArrowBack>}

        {currentIndex < data.fields.galeria.length - 1 && <S.ArrowForward onClick={() => setCurrentIndex((prevIndex) => prevIndex + 1)}>
          <img src={arrowForward} alt="Next" />
        </S.ArrowForward>}
      </S.ModalZoom>

      updateModalBody(body)
    }
  }, [closeModal, currentIndex, modalIsOpen, openModal])

  useEffect(() => {
    (async () => {
      const response = await loadProductById.handle({
        productId: id
      })
      switch (response.status) {
        case 200:
          setData(response.data)
          setStatus(Status.READY)
          return
        default:
          setStatus(Status.ERROR)
      }
    })()
  }, [])

  return <S.Container>
    {(status === Status.LOADING) && <Loading />}

    {(status === Status.ERROR) && <ApiErrorMessage />}

    {(status === Status.READY) && <S.Content>
      <S.CarouselContainer>
        <Carousel
          renderArrowNext={(clickHandler, hasNext) => hasNext && <S.ArrowForward onClick={clickHandler}>
            <img src={arrowForward} alt="Próximo" />
          </S.ArrowForward>}
          renderArrowPrev={(clickHandler, hasPrev) => hasPrev && <S.ArrowBack onClick={clickHandler}>
            <img src={arrowBack} alt="Voltar" />
          </S.ArrowBack>}
          renderThumbs={() => data.fields.galeria.map((thumb, index) => <img key={index} src={thumb.imagem} alt="" />)}
          selectedItem={currentIndex}
          showIndicators={false}
          showStatus={false}
        >
          {data.fields.galeria.map((img, index) => <S.ZoomInContainer key={index}>
            <S.MagnifierButton onClick={() => handleOpenModal(index)}>
              <Magnifier />
            </S.MagnifierButton>

            <a href={img.imagem} download={`${data.product.post_title}-${index}.png`}>
              <span>Baixar imagem</span>
              <img src={img.imagem} alt="" />
            </a>
          </S.ZoomInContainer>)}
        </Carousel>
      </S.CarouselContainer>

      <S.TextContentContainer>
        <S.UnderlinedTitle>{data.product.post_title}</S.UnderlinedTitle>

        <S.TextContent>
          <p dangerouslySetInnerHTML={{ __html: data.product.post_content }} />
        </S.TextContent>
      </S.TextContentContainer>
    </S.Content>}
  </S.Container>
}
