import { useCallback, useEffect, useState, type ChangeEvent, type ReactElement } from 'react'
import ErrorComponent from '../ErrorComponent'
import type InputTextProps from './props'
import * as S from './styled'

export default function InputText ({
  as = 'input',
  error,
  label,
  mask,
  name,
  onChange,
  value,
  ...rest
}: InputTextProps): ReactElement {
  const [internalValue, setInternalValue] = useState<string>('')

  const handleValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    if (mask != null) value = mask(value)

    if (onChange != null) {
      onChange(value)
      setInternalValue(value)
    }
  }, [mask, name, onChange])

  useEffect(() => {
    value !== undefined && setInternalValue(value)
  }, [value])

  return <S.Container>
    <S.Label htmlFor={name}>{label}</S.Label>
    <S.Input
      as={as}
      id={name}
      name={name}
      onChange={handleValueChange}
      value={internalValue !== null && internalValue !== undefined && internalValue !== '' ? internalValue : ''}
      {...rest}
    />

    {error != null && <ErrorComponent>{error}</ErrorComponent>}
  </S.Container>
}
