import {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'
import Modal from 'react-modal'

interface ModalContextValue {
  closeModal: () => void
  modalIsOpen: boolean
  openModal: (config: ModalConfig) => void
  updateModalBody: (body: string | ReactElement | null) => void
}

export interface ModalConfig {
  body?: string | ReactElement | null
  content?: Modal.Styles['content']
  overlay?: Modal.Styles['overlay']
  shape?: 'circle' | 'square'
}

const MODAL_SHAPE: any = {
  circle: '16.875rem',
  square: '37.5rem'
}

const ModalContext = createContext<ModalContextValue | null>(null)

const CustomModal = Modal as ComponentType<ReactModal['props']>

export default function ModalProvider ({ children }: PropsWithChildren): ReactElement {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [body, setBody] = useState<string | null | ReactElement>('')
  const [content, setContent] = useState<Modal.Styles['content']>({})
  const [overlay, setOverlay] = useState<Modal.Styles['overlay']>()
  const [shape, setShape] = useState<string>('circle')
  const modalRef = useRef<HTMLDivElement | null>(null)
  const modalContentRef = useRef<HTMLDivElement | null>(null)

  const customStyles: Modal.Styles = {
    content: {
      aspectRatio: shape === 'circle' ? 1 : 'auto',
      backgroundColor: '#fff',
      borderRadius: shape === 'square' ? '36px' : '50%',
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
      height: 'fit-content',
      left: '50%',
      maxWidth: MODAL_SHAPE[shape],
      overflow: 'visible',
      padding: '0',
      position: 'fixed',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '85%',
      ...content
    },
    overlay
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (modalContentRef.current != null && !modalContentRef.current.contains(event.target as Node)) {
      setIsOpen(false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const closeModal = useCallback((callback?: () => void) => {
    setIsOpen(false)
    document.removeEventListener('mousedown', handleClickOutside)

    if (callback != null && modalRef.current != null) {
      modalRef.current.addEventListener('animationend', callback, { once: true })
    }
  }, [handleClickOutside])

  const openModal = useCallback(({ body, content, overlay, shape }: ModalConfig) => {
    setIsOpen(true)
    setBody(body ?? null)
    setContent(content)
    setOverlay(overlay)
    shape != null && setShape(shape)
    document.addEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  const updateModalBody = useCallback((newBody: string | ReactElement | null) => setBody(newBody), [])

  const modalContextValue: ModalContextValue = {
    closeModal,
    modalIsOpen,
    openModal,
    updateModalBody
  }

  return <ModalContext.Provider value={modalContextValue}>
    {children}
    <CustomModal
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
    >
      {body}
    </CustomModal>
  </ModalContext.Provider>
}

export function useModal (): ModalContextValue {
  const context = useContext(ModalContext)

  if (context == null) throw new Error('useModal must be used within a ModalProvider')

  return context
}
