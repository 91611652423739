import styled from 'styled-components'

export const Container = styled.footer`
  align-items: center;
  background: var(--secondary);
  background: linear-gradient(to bottom, var(--secondary) 0%, var(--primary) 100%);
  background: -webkit-linear-gradient(top, var(--secondary) 0%, var(--primary) 100%);
  display: flex;
  gap: 16px;
  justify-content: space-around;
  padding: 32px 28px;

  p, span {
    color: var(--white);
  }

  @media (max-width: 764px) {
    align-items: center;
    flex-direction: column-reverse;
    gap: 24px;

    p, span, a {
      font-size: 10px;
    }
  }
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  a {
    color: var(--white);
    text-decoration: none;
  }

  @media (max-width: 764px) {
    flex-direction: column;
    gap: 16px;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
    }

    p, span, a {
      font-size: 10px;
    }
  }
`

export const IconsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`
