import { ReactElement } from 'react'
import { PageState } from '../../../data/enums/PageState'
import useLoadPageBySlug from '../../../hooks/useLoadPageBySlug'
import ContactListItem from './ContactListItem'
import contactInfo from './contactInfo'
import * as S from './styled'

export default function ContactForm (): ReactElement {
  const { data, state } = useLoadPageBySlug<any>('contato')

  if (state === PageState.LOADING) return <>Carregando...</>
  if (state === PageState.NO_CONTENT) return <>Sem conteúdo</>

  return <S.Container>
    <S.Content>
      <S.ContactOptions>

        <h1>Entre em contato</h1>

        {contactInfo.map(({ options, title }) => <ContactListItem
          key={title}
          options={options}
          title={title}
        />)}
      </S.ContactOptions>

      <S.ContactForm>
        <span>Estamos sempre à disposição para ouvir suas sugestões, responder suas dúvidas e ajudar com eventuais problemas. Entre em contato!</span>

        <S.GridContainer dangerouslySetInnerHTML={{ __html: data.page.post_content }}></S.GridContainer>
      </S.ContactForm>

    </S.Content>
  </S.Container>
}
