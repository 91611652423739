import { ReactElement } from 'react'
import PalletProps from '../props'
import * as S from './styled'

export default function Attributes ({ data }: PalletProps): ReactElement {
  return <S.Container>
    <figure>
      <img src={data.fields.atributos.imagem} alt="" />
    </figure>

    <S.Content>
      <h1>{data.fields.atributos.titulo}</h1>

      <ul>
        {data.fields.atributos.items.map((item: { imagem: string, titulo: string }) => <li key={item.titulo}>
          <img src={item.imagem} alt="" />

          {item.titulo}
        </li>)}
      </ul>
    </S.Content>
  </S.Container>
}
