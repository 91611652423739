import { ReactElement } from 'react'
import CoatingProps from '../props'
import * as S from './styled'

export default function Benefits ({ data }: CoatingProps): ReactElement {
  return <S.Container>
    <S.Content>
      <S.UnderlinedTitle>Benefícios:</S.UnderlinedTitle>

      <S.ItemContainer>
        <h3>{data.fields.beneficios[0].titulo}</h3>

        <S.ItemContent>
          <S.TextContainer dangerouslySetInnerHTML={{ __html: data.fields.beneficios[0].conteudo }}></S.TextContainer>
        </S.ItemContent>
      </S.ItemContainer>

      <S.ItemContainer>
        <h3>{data.fields.beneficios[1].titulo}</h3>

        <S.ItemContent>
          <figure>
            <img src={data.fields.beneficios[1].imagem} alt="" />
          </figure>

          <S.TextContainer dangerouslySetInnerHTML={{ __html: data.fields.beneficios[1].conteudo }}></S.TextContainer>
        </S.ItemContent>
      </S.ItemContainer>

      <S.ItemContainer>
        <h3>{data.fields.beneficios[2].titulo}</h3>

        <S.ItemContent className="maintenance">
          <S.TextContainer dangerouslySetInnerHTML={{ __html: data.fields.beneficios[1].conteudo }}></S.TextContainer>

          <figure>
            <img src={data.fields.beneficios[2].imagem} alt="" />
          </figure>
        </S.ItemContent>
      </S.ItemContainer>
    </S.Content>
  </S.Container>
}
