import styled, { css } from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  max-width: 1000px;
  width: 100%;
`

export const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 8px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: all 0.5s linear;

  img {
    max-width: 16px;
    width: 100%;
  }

  &:hover {
    background-color: rgba(0, 0, 0, .1);
  }
`

export const TabsContainer = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  width: 100%;

  @media (max-width: 1198px) {
    gap: 16px;
  }
`

export const TabButton = styled.button<{ $active: boolean }>`
  ${({ $active }) => $active
    ? css`
      background: radial-gradient(81.82% 234.78% at 50% 50%, #103272 0%, #0077B6 100%);
      color: var(--white);
      font-weight: 800;
    `
    : css`
      background: transparent;
      color: var(--text-primary);

      &:hover {
        font-weight: 600;
      }
    `
  }

  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 15pt;
  line-height: 27px;
  height: 55px;
  max-width: fit-content;
  outline: none;
  padding-inline: 20px;
  transition: all .5s ease-in-out;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 1198px) {
    font-size: 12pt;
    height: 48px;
  }

  @media (max-width: 500px) {
    font-size: 9pt;
    height: 36px;
  }
`
