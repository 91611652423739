import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import facebook from '../../assets/img/footer/facebook.svg'
import instagram from '../../assets/img/footer/instagram.svg'
import linkedIn from '../../assets/img/footer/linkedin.svg'
import whatstApp from '../../assets/img/footer/whatsapp.svg'
import youtube from '../../assets/img/footer/youtube.svg'
import * as S from './styled'

interface FooterProps {
  office: string
  factory: string
  phone: string
}

export default function Footer ({ office, factory, phone }: FooterProps): ReactElement {
  return <S.Container>
    <div>
      <p><strong>Polibras Minas Plástico Ltda - CNPJ: 04.253.650/0001-08</strong></p>
      <span>Acreditamos no Poder da Inovação para Transformar.</span>
      <br />
      <br />
      <p><strong>Escritório: </strong><span dangerouslySetInnerHTML={{ __html: office }}></span></p>
      <p><strong>Fábrica: </strong><span dangerouslySetInnerHTML={{ __html: factory }}></span></p>
    </div>

    <S.ContactContainer>
      <div>
        <p><strong>Conecte-se conosco</strong></p>
        <S.IconsContainer>
          <Link target="_blank" to="https://www.facebook.com/polibrasoficial">
            <img src={facebook} alt="Facebook" />
          </Link>

          <Link target="_blank" to="https://www.instagram.com/polibras_oficial/">
            <img src={instagram} alt="Instagram" />
          </Link>

          <Link target="_blank" to="https://web.whatsapp.com/send?phone=5508007711331&text=Ol%C3%A1.%20Visitei%20o%20seu%20site%20e%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20produtos.">
            <img src={whatstApp} alt="WhatsApp" />
          </Link>

          <Link target="_blank" to="https://www.youtube.com/user/Polibrasbr">
            <img src={youtube} alt="YouTube" />
          </Link>

          <Link target="_blank" to="https://www.linkedin.com/company/polibrasminasplasticos/">
            <img src={linkedIn} alt="LinkedIn" />
          </Link>
        </S.IconsContainer>
      </div>

      <div>
        <p><strong>Contate-nos</strong></p>
        <p>{phone}</p>
      </div>

      <div>
        <p><strong>Quer entrar em contato com a gente?</strong></p>
        <Link to="mailto:vendas@polibras.com.br">vendas@polibras.com.br</Link>
      </div>

      <div>
        <a href="/termos-de-privacidade">Termos de Privacidade</a>
      </div>
    </S.ContactContainer>
  </S.Container>
}
