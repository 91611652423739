import styled from 'styled-components'

export const Container = styled.div`
  p, a {
    color: var(--text-primary);

    strong {
      color: #384141;
    }
  }

  @media (max-width: 1124px) {
    font-size: 10.5pt;
  }

  @media (max-width: 950px) {
    font-size: 9pt;
  }
`
