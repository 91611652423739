import { ReactElement } from 'react'
import { PageState } from '../../../data/enums/PageState'
import useLoadPageBySlug from '../../../hooks/useLoadPageBySlug'
import Benefits from './Benefits'
import Intro from './Intro'
import * as S from './styled'

export default function Coating (): ReactElement {
  const { data, state } = useLoadPageBySlug<any>('solucoes-para-negocios/revestimento')

  if (state === PageState.LOADING) return <>Carregando...</>
  if (state === PageState.NO_CONTENT) return <>Nenhum conteúdo disponivel</>

  return <S.Container>
    <Intro data={data} />

    <Benefits data={data} />
  </S.Container>
}
