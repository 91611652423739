import { ReactElement } from 'react'
import InfoProps from './props'
import * as S from './styled'

export default function Info ({ agent, cellphone, companyName, ddd, email, internalSupport, phone, regionalManager, zoneCity }: InfoProps): ReactElement {
  const phoneNumber = (): string => {
    if (phone !== '' && cellphone !== '') return `(${ddd}) ${phone} / (${ddd}) ${cellphone}`

    return `(${ddd}) ${phone !== '' ? phone : cellphone}`
  }

  return <S.Container>
    <p><span>Zona/Cidade: </span>{zoneCity}</p>
    <p><span>Razão Social: </span>{companyName}</p>
    <p><span>Representante: </span>{agent}</p>
    <p><span>E-mail: </span>{email}</p>
    <p><span>Números de telefone: </span>{phoneNumber()}</p>
    <p><span>Gerente Regional: </span>{regionalManager}</p>
    <p><span>Analista de Vendas: </span>{internalSupport}</p>
  </S.Container>
}
