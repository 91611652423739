import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-inline: auto;
  max-width: 1280px;
  padding: 40px 32px 68px;
  width: 100%;

  h2 {
    color: var(--text-title);
    font-size: 22pt;
    font-weight: 800;
    text-align: center;

    @media (max-width: 764px) {
      font-size: 15pt;
    }
  }
`

export const ImagesContainer = styled.div.attrs(() => {
  return {
    className: 'container'
  }
})`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, 384px);
  place-content: center;
  width: 100%;

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const OverlayContainer = styled.div`
  position: relative;

  &:hover {
    figure img {
      scale: 1.1;
    }
  }
`

export const Image = styled.figure<{ $visible: boolean }>`
  height: 100%;
  line-height: 0;
  margin: 0;
  opacity: ${({ $visible }) => $visible ? 1 : 0};
  overflow: hidden;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all .6s linear;
    width: 100%;
  }
`

export const Overlay = styled.div`
  cursor: zoom-in;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  transition: opacity .8s ease;

  &:hover {
    opacity: 0;
  }
`

export const ModalZoom = styled.figure`
  line-height: 0;
  margin: 0;
  overflow: hidden;
  max-width: 1000px;
  width: 100%;

  img {
    transition: all .6s linear;
    width: 100%;
  }
`
