import { ReactElement, useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Link } from 'react-router-dom'
import { PageState } from '../../data/enums/PageState'
import { LoadHomeData } from '../../domain/use-case/LoadHomeData'
import about from './assets/HOME_POLIBRAS1.png'
import notebook from './assets/notebook.svg'
import weight from './assets/peso.svg'
import { HomeProps } from './props'
import * as S from './styled'

export default function Home ({ loadHome }: HomeProps): ReactElement {
  const [isImageVisible, setIsImageVisible] = useState(false)
  const [isInstitutionalVisible, setIsInstitutionalVisible] = useState(false)
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [data, setData] = useState<LoadHomeData.Result>({} as unknown as LoadHomeData.Result)

  useEffect(() => {
    const handleAnimation = (): void => {
      const image = document.querySelector('.slide-in-image img')

      if (image === null) return

      const imagePosition = image.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (imagePosition < windowHeight / 0.8) {
        setIsImageVisible(true)
      }
    }

    if (window.innerWidth < 1124) {
      handleAnimation()
    } else {
      window.addEventListener('scroll', handleAnimation)
    }

    return () => window.removeEventListener('scroll', handleAnimation)
  }, [])

  useEffect(() => {
    const handleScroll = (): void => {
      const div = document.querySelector('.institutional div')

      if (div === null) return

      const divPosition = div.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (divPosition < windowHeight / 2) {
        setIsInstitutionalVisible(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    (async () => {
      setState(PageState.LOADING)
      const response = await loadHome.handle()
      setData(response)
      setState(PageState.READY)
    })()
  }, [])

  if (state === PageState.LOADING) return <>Carregando</>

  return <main>
    <S.HistoryBannerBg>
      <S.HistoryBannerContainer>
        <h1 dangerouslySetInnerHTML={{ __html: data.hero.title }}></h1>

        <p dangerouslySetInnerHTML={{ __html: data.hero.subTitulo }}></p>

        <Link to={data.hero.botao.link}>
          {data.hero.botao.titulo}
        </Link>
      </S.HistoryBannerContainer>
    </S.HistoryBannerBg>

    <S.VideoContainer>
      <video src={data.video} autoPlay loop muted />
    </S.VideoContainer>

    <S.SectionsContainer>
      <S.AboutSection>
        <figure className="slide-in-image">
          <img src={about} alt="" className={`${isImageVisible ? 'visible' : ''}`} />
        </figure>

        <S.AboutContent>
          <div>
            <h1>Somos a <span>POLIBRAS</span></h1>

            <p dangerouslySetInnerHTML={{ __html: data.about.descricao }}></p>

            <S.ReadMoreLink to={data.about.pagina}>
              Leia mais
            </S.ReadMoreLink>
          </div>
        </S.AboutContent>
      </S.AboutSection>

      <S.SolutionsSection>
        <S.SolutionsContent>
          <h1>
            Uma empresa,
            <br />
            múltiplas soluções
          </h1>

          <p dangerouslySetInnerHTML={{ __html: data.solutions.solucoes }}></p>
        </S.SolutionsContent>

        <S.SolutionImageCardsContainer>
          <S.SolutionImageCardBg>
            <S.SolutionImageCardContent>
              <p>{data.solutions.link_1.pagina[0].post_title}</p>

              <S.ReadMoreLink to={data.solutions.link_1.pagina[0].post_name}>
                Leia mais
              </S.ReadMoreLink>
            </S.SolutionImageCardContent>
          </S.SolutionImageCardBg>

          <S.SolutionImageCardBg>
            <S.SolutionImageCardContent>
              <p>{data.solutions.link_2.pagina[0].post_title}</p>

              <S.ReadMoreLink to={data.solutions.link_2.pagina[0].post_name}>
                Leia mais
              </S.ReadMoreLink>
            </S.SolutionImageCardContent>
          </S.SolutionImageCardBg>
        </S.SolutionImageCardsContainer>
      </S.SolutionsSection>
    </S.SectionsContainer>

    <S.InstitutionalBannerBg>
      <S.InstitutionalBanner className="institutional">
        <S.InstitutionalContent>
          <S.Text>
            Nossos produtos fazem <br />parte da vida de
            <br />
            <S.LargeText> milhões <br /></S.LargeText>
            <S.BottomText>de brasileiros</S.BottomText>
          </S.Text>
        </S.InstitutionalContent>

        <hr />

        <S.InstitutionalContent>
          <figure>
            <img src={weight} alt="" />
          </figure>

          <S.Text>
            Processamos <br />
            <S.LargeNumber end={data.processing} trigger={isInstitutionalVisible} />
            <S.MediumText> milhões <br /></S.MediumText>
            <S.BottomText>de kg ao ano</S.BottomText>
          </S.Text>
        </S.InstitutionalContent>

        <hr />

        <S.InstitutionalContent>
          <figure>
            <img src={notebook} alt="" />
          </figure>

          <S.Text>
            Produzimos <br />
            <S.LargeNumber end={data.pieces} trigger={isInstitutionalVisible} className="production" />
            <S.MediumText> milhões <br /></S.MediumText>
            <S.BottomText className="production">de peças ao ano</S.BottomText>
          </S.Text>
        </S.InstitutionalContent>
      </S.InstitutionalBanner>
    </S.InstitutionalBannerBg>

    <S.EnvironmentBannerBg bannerenvironment={data.environment.fotoDeFundo}>
      <S.EnvironmentBannerContent>
        <S.EnvironmentTopContent>
          <h2 dangerouslySetInnerHTML={{ __html: data.environment.titulo }}></h2>

          <div dangerouslySetInnerHTML={{ __html: data.environment.descricao }}></div>
        </S.EnvironmentTopContent>

        <S.EnvironmentBottomContent dangerouslySetInnerHTML={{ __html: data.environment.reciclagem }}></S.EnvironmentBottomContent>
      </S.EnvironmentBannerContent>
    </S.EnvironmentBannerBg>

    <S.LatestNewsSection>
      <h1>Últimas notícias</h1>

      <S.CarouselContainer>
        <S.LatestNewsCardContainer>
          {data.lastPosts.map(({ id, permalink, image, title }) => <S.LatestNewCard key={id}>
            <figure>
              <img src={image} alt="" />
            </figure>

            <p>{title}</p>

            <S.NewsReadMoreLink
              href={permalink}
              title={title}
            >
              Leia mais
            </S.NewsReadMoreLink>
          </S.LatestNewCard>)}
        </S.LatestNewsCardContainer>
      </S.CarouselContainer>
    </S.LatestNewsSection>
  </main>
}
