export default [
  {
    options: ['0800 771 1331'],
    title: 'SAC POLIBRAS (PARA CONSUMIDORES)'
  },
  {
    options: ['(11) 4182-8000', '0800 771 1331 WhatsApp', 'vendas@polibras.com.br', 'Televendas para todo o Brasil'],
    title: 'ATENDIMENTO (PARA LOJISTAS)'
  },
  {
    options: ['(11) 4182-8013', '(11) 99154-3275', 'vendas.customizados@polibras.com.br'],
    title: 'INDUSTRIAL (PRODUTOS CUSTOMIZADOS)'
  },
  {
    options: ['rh@polibras.com.br'],
    title: 'TRABALHE CONOSCO'
  },
  {
    options: ['Segunda a sexta, das 8h às 17h30'],
    title: 'HORÁRIO DE ATENDIMENTO'
  },
  {
    options: [],
    title: 'ONDE ESTAMOS'
  },
  {
    options: ['Av. Queiroz Filho, 1700 - Torre C - CJ 306', 'Condomínio Villa Lobos Office Park', 'São Paulo/ SP - CEP: 05319-000'],
    title: 'Matriz'
  },
  {
    options: ['Av. Brasil, 555 - Cambuí/ MG', 'CEP: 37600-000'],
    title: 'Parque Industrial'
  }
]
