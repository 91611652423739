import styled, { css } from 'styled-components'

export const TimelineContainer = styled.div`
  align-items: center;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 32px 0;
  width: 100%;

  figure {
    width: 100%;

    img {
      width: 100%;
    }
  }
`

export const TimelineImageContainer = styled.div`
  max-width: 1366px;
  position: relative;
`

interface HoverBalloonProps {
  $left: number
  $img: string
  $paddingBottom?: boolean
  $paddingTop?: boolean
  $top: number
}

const HEIGHT = 153 * 100 / 1230

export const HoverBalloon = styled.div<HoverBalloonProps>`
  ${({ $img, $left, $paddingBottom = false, $paddingTop = false, $top }) => css`
    background-image: url(${$img});
    background-position: ${$paddingBottom ? 'top' : 'bottom'};
    left: ${$left * 100 / 1366}%;
    height: ${HEIGHT}%;
    padding-bottom: ${$paddingBottom ? HEIGHT * 2 : 0}%;
    padding-top: ${$paddingTop ? HEIGHT * 2 : 0}%;
    top: ${$top * 100 / 764}%;
    width: ${190 * 100 / 1366}%;
  `}

  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  position: absolute;
  transition: opacity .2s linear;

  &:hover {
    opacity: 1;
  }
`
