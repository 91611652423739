import { AxiosInstance } from 'axios'
import { LoadHomeData } from '../../domain/use-case/LoadHomeData'
import { mapToEnvironment } from '../helpers/mapToEnvironment'
import { mapToHero } from '../helpers/mapToHero'
import { mapToPost } from '../helpers/mapToPost'

export class ApiLoadHomeData implements LoadHomeData {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadHomeData.Result> {
    const response = await this.api.get('/wp-json/plb2024/v1/home/')
    return {
      ...response.data,
      hero: mapToHero(response.data.hero),
      lastPosts: response.data.lastPosts.map(mapToPost),
      environment: mapToEnvironment(response.data.environment),
      processing: Number.parseInt(response.data.processing),
      pieces: Number.parseInt(response.data.pieces)
    }
  }
}
