import { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ProductsList from '../../components/ProductsList'
import { PageState } from '../../data/enums/PageState'
import { LoadPageBySlug } from '../../domain/use-case/LoadPageBySlug'
import { LoadProductsByCartegory } from '../../domain/use-case/LoadProductsByCartegory'
import * as S from './styled'

interface CricutProps {
  loadProductsByCartegory: LoadProductsByCartegory
  loadPageBySlug: LoadPageBySlug<any>
}

export default function Cricut ({ loadProductsByCartegory, loadPageBySlug }: CricutProps): ReactElement {
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [data, setData] = useState<LoadPageBySlug.Result<any>>({} as unknown as LoadPageBySlug.Result<any>)

  useEffect(() => {
    (async () => {
      try {
        const response = await loadPageBySlug.handle('papelaria/cricut')
        setData(response)
        console.log(response)
        setState(PageState.READY)
      } catch (error) {
        const err = error as Error
        switch (err.name) {
          case 'Sem conteúdo':
            setState(PageState.NO_CONTENT)
        }
      }
    })()
  }, [])

  if (state === PageState.LOADING) return <>...</>
  if (state === PageState.NO_CONTENT) return <>Não temos conteúdo</>

  return <S.Container>
    <S.TopBannerBg $background={data.fields.hero.imagem}>
      <S.TopBannerContent>
        <p dangerouslySetInnerHTML={{ __html: data.fields.hero.titulo }}></p>
      </S.TopBannerContent>
    </S.TopBannerBg>

    <S.DistributorContainer $background={data.fields.distribuidora.imagem_de_fundo.url}>
      <S.DistributorContent>
        <div>
          <h1 dangerouslySetInnerHTML={{ __html: data.fields.distribuidora.titulo }}></h1>

          <br />
          <br />

          <p dangerouslySetInnerHTML={{ __html: data.fields.distribuidora.conteudo }}></p>
        </div>
      </S.DistributorContent>

      <figure>
        <img src={data.fields.distribuidora.imagem_de_fundo.url} alt="" />
      </figure>
    </S.DistributorContainer>

    <S.CreativityContainer>
      <S.UnderlinedTitle dangerouslySetInnerHTML={{ __html: data.fields.plataforma.titulo }} />

      <p dangerouslySetInnerHTML={{ __html: data.fields.plataforma.conteudo }}></p>
    </S.CreativityContainer>

    <S.HowItWorksContainer>
      <S.TitleContainer>
        <h1>Como ele funciona?</h1>
      </S.TitleContainer>

      <S.StepsContainer>
        <S.Steps>
          {data.fields.como_funciona.passo.map((p: any, index: number) => <div key={index}>
            <h3>{p.titulo}</h3>
              <p dangerouslySetInnerHTML={{ __html: p.conteudo }}></p>
          </div>)}
        </S.Steps>
      </S.StepsContainer>

      <S.Footnote dangerouslySetInnerHTML={{ __html: data.fields.como_funciona.conteudo }} />
    </S.HowItWorksContainer>

    <S.ApplicationsContainer>
      <S.ThinUnderlinedTitle>Aplicações</S.ThinUnderlinedTitle>

      <div>
        {
          data.fields.aplicacoes.map((img: any, index: number) => <figure key={index}>
            <img src={img.imagem.url} alt={img.imagem.alt} />
          </figure>)
        }
      </div>
    </S.ApplicationsContainer>

    <S.EquipmentsContainer>
      <S.ThinUnderlinedTitle>Nossos Equipamentos</S.ThinUnderlinedTitle>

      <ProductsList slug="cricut-equipamento" loadProductsByCartegory={loadProductsByCartegory} />
    </S.EquipmentsContainer>

    <S.ButtonsContainer>
      <Link rel="noreferrer" target="_blank" to="https://www.lojapolibras.com.br/">
        <button>
          <span>
            Compre Online
          </span>
        </button>
      </Link>

      <Link to="/contato">
        <button>
          <span>
            Seja um Revendedor
          </span>
        </button>
      </Link>
    </S.ButtonsContainer>

    <S.ProductsContainer>
      <S.ThinUnderlinedTitle>Nossos Produtos</S.ThinUnderlinedTitle>

      <ProductsList slug="cricut-produtos" loadProductsByCartegory={loadProductsByCartegory} />
    </S.ProductsContainer>
  </S.Container>
}
