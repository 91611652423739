import { ReactElement } from 'react'
import PlatesProps from '../props'
import * as S from './styled'

export default function Personalized ({ data }: PlatesProps): ReactElement {
  return <S.Container>
    <S.Content>
      <h1>{data.fields.personalizacao.titulo}</h1>

      <ul>
        {data.fields.personalizacao.conteudo.map((item: { icone: string, texto: string }) => <li>
          <img src={item.icone} alt="" />

          {item.texto}
        </li>)}
      </ul>
    </S.Content>

    <figure>
      <img src={data.fields.personalizacao.imagem} alt="" />
    </figure>
  </S.Container>
}
