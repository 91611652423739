import styled from 'styled-components'

export const Container = styled.main`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1110px;
  padding: 100px 28px;

  h1 {
    text-align: center;
  }
`
