import { ReactElement } from 'react'
import * as S from './styled'

export default function TermsOfPrivacy (): ReactElement {
  return <S.Container>
    <h1>TERMO DE PRIVACIDADE</h1>

    <h3>Compromisso com a Proteção e Privacidade de Dados</h3>

    <p>Esta Política de proteção e privacidade de dados foi criada para demonstrar o compromisso da empresa POLIBRAS com a segurança digital e física das informações dos usuários coletadas.</p>
    <p>Nós, da POLIBRAS, empresa privada, inscrita sob o CNPJ 04.253.650/0001-08, valorizamos a proteção e privacidade dos dados pessoais e/ou sensíveis, sejam eles de clientes, fornecedores, colaboradores, parceiros ou qualquer outro titular com que nos relacionamos.</p>

    <h3>Dados coletados</h3>

    <p>A garantia da confidencialidade dos dados pessoais e/ou sensíveis dos titulares de dados do nosso site é importante para a POLIBRAS. As informações pessoais e/ou sensíveis coletadas e disponibilizadas por você, são utilizados para cumprir suas solicitações de nossos produtos ou serviços e comprimentos das leis vigentes. Podemos coletar as informações pessoais e/ou sensíveis conforme descrito abaixo:</p>
    <p>Nome completo; Nome social; CPF; RG; Sexo; Data de nascimento; Endereço de e-mail; Telefone fixo; Telefone móvel; Endereço completo (Número, CEP, Cidade, Bairro, Estado e País); Endereço de entrega; Endereço de cobrança; Dados do cartão de crédito;</p>

    <h3>Finalidade da coleta dos dados</h3>

    <p>Nós coletamos dados pessoais e/ou sensíveis com a finalidade de viabilizar a comercialização dos nossos produtos ou serviços, seja nos processos de venda, emissão de faturas, cobranças, entregas de matérias, encaminhamento de promoções, elaboração de contratos, cumprimento de obrigações legais ou regulatórias. Assim sendo, mantemos registros de suas compras, de seus downloads, dos acordos celebrados entre você e a POLIBRAS, dos produtos e dos serviços fornecidos a você, de seus contatos e comunicações.</p>

    <h3>Armazenamento dos dados</h3>

    <p>Os dados que coletamos são armazenados adotando meios de segurança físico e digital (nuvem), durante o período de relacionamento com o titular de dados pessoais e/ou sensíveis ou pelo tempo exigido para cumprimento de obrigações legais, contratuais ou regulatórias.</p>

    <h3>Compartilhamento de dados</h3>

    <p>Para garantir um melhor atendimento, em algumas situações, compartilhamos dados pessoais com fornecedores, parceiros ou autoridades, porém todo compartilhamento é feito dentro das diretrizes de segurança, privacidade e proteção estabelecidos por nossos especialistas para atender aos requisitos especificados na Lei Geral de Proteção de Dados. A POLIBRAS não vende, troca, aluga ou divulga os seus dados pessoais a terceiros e nem a ninguém.</p>

    <h3>Segurança dos dados</h3>

    <p>A segurança dos dados pessoais dentro do nosso ambiente é estruturada sobre os pilares de confidencialidade, integridade e disponibilidade das informações. Nós adotamos uma Política de Segurança da informação com foco na prevenção de incidentes, detecção e correção de vulnerabilidades.</p>

    <h3>Direitos do titular de dados pessoais e/ou sensíveis</h3>

    <p>O titular de dados poderá solicitar à POLIBRAS, em qualquer situação, a confirmação de tratamento, informações de compartilhamento, acesso, correção ou portabilidade de seus dados pessoais, bem como a exclusão de seus dados pessoais ou revogação do consentimento de uso, nos termos da LGPD.</p>

    <h3>Do Consentimento e uso dos dados</h3>

    <p>Ao se cadastrar na POLIBRAS o titular de dados está ciente que faremos uso de seus dados conforme acima, para execução dos nossos serviços e/ou contratos. Será solicitado no momento do cadastro o consentimento para encaminhamento de nossas promoções, sendo opcional do titular de dados conceder ou não. Informamos que o tempo de guarda e uso dos dados concedidos como nome, e-mail, SMS e whatsapp, serão usados por um período de até 1 ano para divulgação das nossas campanhas promocionais desde que não haja a revogação do consentimento pelo titular de dados.</p>

    <h3>Alterações da política</h3>

    <p>A POLIBRAS poderá efetuar mudanças e retificações adicionais nesta Política de proteção e privacidade de dados, assumimos o compromisso de publicar a política vigente em nosso site. Solicitamos que leia regularmente esta Política de Privacidade para tomar conhecimento das referidas modificações e de que forma a mesma poderá afetá-lo. A POLIBRAS assume o compromisso de publicar a política vigente em seus sites.</p>

    <h3>Informações de contato</h3>

    <p>Solicitações de atendimento aos direitos do titular de dados poderão ser solicitadas diretamente ao Encarregado de Dados da POLIBRAS, através do e-mail <a href="mailto:contato@polibras.com.br">contato@polibras.com.br</a> com o assunto POLIBRAS.</p>

    <h3>O que são Cookies?</h3>

    <p>Os cookies são arquivos simples, enviados pelo site visitado ao seu computador, e ficam armazenados em seu navegador. Eles guardam informações que ajudam a identificar cada internauta, como o endereço IP, termos pesquisados, conteúdos acessados, configurações salvas, entre outras. Usamos cookies para personalizar conteúdo, anúncios e fornecer recursos de mídia social e analisar nosso tráfego. Também compartilhamos informações sobre o uso de nosso site com nossos parceiros de mídia social, publicidade e análise, que podem combiná-las com outras informações que você forneceu a eles ou que eles coletaram do uso de seus serviços. Aceitar cookies proporcionará a você a melhor experiência de usuário no site, enquanto recusar cookies pode interferir no uso do site. Por exemplo, compras online. Os cookies permitem que o site monitore todos os itens que você colocou em seu carrinho enquanto você continua a navegar.</p>
  </S.Container>
}
