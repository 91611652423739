import styled from 'styled-components'

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;

  figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }
`

export const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 248px);
  max-width: 1100px;
  place-content: center;
  width: 100%;
`

export const OverlayContainer = styled.figure`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    transition: all .6s linear;

    &:hover {
      scale: 1.1;
    }
  }
`
