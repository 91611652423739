import { ReactElement } from 'react'
import MobileNavItem from './MobileNavItem'
import NavListProps from './props'
import * as S from './styled'

export default function MobileNavList ({ handleToggleMenu, ...rest }: NavListProps): ReactElement {
  return <S.NavContainer {...rest}>
    <ul>
      <MobileNavItem
        label="Nossa Empresa"
        onClick={handleToggleMenu}
        to="/nossa-empresa"
      />

      <MobileNavItem
        label="Papelaria"
        onClick={handleToggleMenu}
        submenuItems={[
          {
            to: '/papelaria/cricut',
            label: 'Cricut'
          }
        ]}
        to="/papelaria"
      />

      <MobileNavItem
        label="Soluções para Negócios"
        onClick={handleToggleMenu}
        submenuItems={[
          { to: '/solucoes-para-negocios/armazenagem', label: 'Armazenagem' },
          { to: '/solucoes-para-negocios/chapas', label: 'Chapas PP Corrugado' },
          { to: '/solucoes-para-negocios/folha-separadora', label: 'Folha Separadora' },
          { to: '/solucoes-para-negocios/manga-pallet', label: 'Manga Pallet' },
          { to: '/solucoes-para-negocios/revestimento', label: 'Revestimento' }
        ]}
        to="/solucoes-para-negocios"
      />

      <MobileNavItem
        label="Blog"
        onClick={handleToggleMenu}
        to="/blog"
      />

      <MobileNavItem
        label="Contato"
        onClick={handleToggleMenu}
        to="/contato"
      />
    </ul>
  </S.NavContainer>
}
