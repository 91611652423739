import styled from 'styled-components'
import UT from '../../../../components/UnderlinedTitle'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 28px;
  max-width: 1280px;
  width: 100%;
`

export const UnderlinedTitle = styled(UT)`
  line-height: 56px;
  text-align: center;
  white-space: normal;

  &::before {
    bottom: -5px;
    height: 8px;
    width: 64%;
  }

  @media (max-width: 600px) {
    br {
      display: none;
    }
  }
`

export const Content = styled.div`
  display: grid;
  gap: 12px 8px;
  grid-template-columns: repeat(auto-fill, 286px);
  place-content: center;

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`
