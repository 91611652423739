import { ReactElement } from 'react'
import spreadsheets from '../assets/spreadsheets.png'
import SeparatorSheetsProps from '../props'
import * as S from './styled'

export default function PlasticLayers ({ data }: SeparatorSheetsProps): ReactElement {
  return <S.Container>
    <figure>
      <img src={data.fields.folhas_separadoras.imagem} alt="" />
    </figure>

    <h1>{data.fields.folhas_separadoras.titulo}</h1>

    <S.Content>
      <S.TextContent dangerouslySetInnerHTML={{ __html: data.fields.folhas_separadoras.conteudo }}></S.TextContent>

      <figure>
        <img src={spreadsheets} alt="" />
      </figure>
    </S.Content>
  </S.Container>
}
