import { ReactElement } from 'react'
import Paper from '../../../pages/Paper'
import { makeLoadCategories } from '../makeLoadCategories'
import { makeLoadCategoriesByParent } from '../makeLoadCategoriesByParent'
import { makeLoadPageBySlug } from '../makeLoadPageBySlug'
import { makeLoadProductsByCartegory } from '../makeLoadProductsByCartegory'

export const makePaperPage = (): ReactElement => {
  const loadPageBySlug = makeLoadPageBySlug()
  const loadCategories = makeLoadCategories()
  const loadCategoriesByParent = makeLoadCategoriesByParent()
  const loadProductsByCartegory = makeLoadProductsByCartegory()
  return <Paper
    loadPageBySlug={loadPageBySlug}
    loadCategories={loadCategories}
    loadCategoriesByParent={loadCategoriesByParent}
    loadProductsByCartegory={loadProductsByCartegory}
  />
}
