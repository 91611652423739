import { ReactElement } from 'react'
import Agents from './Agents'
import ContactForm from './ContactForm'
import * as S from './styled'

export default function Contact (): ReactElement {
  return <S.Container>
    <S.Content>
      <ContactForm />

      <Agents />
    </S.Content>
  </S.Container>
}
