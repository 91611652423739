import { ReactElement } from 'react'
import CoatingProps from '../props'
import * as S from './styled'

export default function Intro ({ data }: CoatingProps): ReactElement {
  return <S.Container>
    <S.Content>
      <S.TextContainer>
        <h1>{data.fields.revestimento.titulo}</h1>

        <div dangerouslySetInnerHTML={{ __html: data.fields.revestimento.conteudo }}></div>
      </S.TextContainer>
    </S.Content>

    <figure>
      <img src={data.fields.revestimento.imagem} alt="" />
    </figure>
  </S.Container>
}
