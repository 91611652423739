import { PostModel } from '../entities/PostModel'

export const mapToPost = (post: any): PostModel => {
  return {
    id: post.ID,
    image: post.image,
    title: post.post_title,
    permalink: post.permalink
  }
}
