import styled from 'styled-components'

export const Container = styled.div`
  align-self: center;
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  h3 {
    align-self: flex-start;
    color: var(--text-primary);
    font-weight: 500;
    font-size: 12pt;
    margin: 32px 0 0;
  }
`

export const Content = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 764px) {
    align-self: flex-start;
    flex-direction: column;
  }
`

export const ContactOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    color: var(--text-primary);
    font-size: 18pt;
  }

  @media (min-width: 764px) {
    max-width: 33%;
  }
`

export const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 532px;

  span {
    font-size: 10.5pt;
  }
`

export const GridContainer = styled.div`
  background-color: rgba(246, 246, 246, 1);
  display: grid;
  flex: 1;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  justify-self: center;
  height: fit-content;
  padding: 32px;

  label {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    margin: 0px;
    padding: 0px;
    color: var(--text-primary);
    font-size: 10.5pt;
    font-weight: 700;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border: 2px solid rgb(202, 193, 193);
    color: var(--text-secondary);
    font-family: "Open sans", sans-serif;
    font-size: 12pt;
    font-weight: 300;
    height: 45px;
    outline: none;
    padding-inline: 10px;
    width: 100%;
  }

  textarea {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border: 2px solid rgb(202, 193, 193);
    color: var(--text-secondary);
    font-family: "Open sans", sans-serif;
    font-size: 12pt;
    font-weight: 300;
    height: 45px;
    outline: none;
    padding-inline: 10px;
    width: 100%;
    min-height: 160px;
  }

  button, input[type="submit"] {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    margin: 0px;
    background: linear-gradient(45deg, var(--secondary), var(--primary));
    border: 1px solid transparent;
    border-radius: 36px;
    color: var(--white);
    padding: 12px 40px;
    outline: none;
    transition: 0.5s;
    width: fit-content;
    }

  > div {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    margin: 0px;
    padding: 0px;
    position: relative;
    width: 100%;
    grid-column: span 3;

    &:nth-of-type(4) {
      grid-column: span 1;
    }

    &:nth-of-type(5) {
      grid-column: span 2;
      grid-row: 5;
    }

    &:nth-of-type(6) {
      grid-column: span 1;
      grid-row: 5;
    }
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);

    > div {
      grid-column: span 2;

      &:nth-of-type(4) {
        grid-column: auto;
      }

      &:nth-of-type(5) {
        grid-column: auto;
        grid-row: 5;
      }

      &:nth-of-type(6) {
        grid-column: auto;
        grid-row: 5;
      }
    }
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;

    > div {
      grid-column: auto;

      &:nth-of-type(4) {
        grid-column: auto;
      }

      &:nth-of-type(5) {
        grid-column: auto;
        grid-row: initial;
      }

      &:nth-of-type(6) {
        grid-column: auto;
        grid-row: initial;
      }
    }
  }
`

export const SubmitButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 2;
  place-self: center;

  button {
    background: linear-gradient(45deg, var(--secondary), var(--primary));
    border: 1px solid transparent;
    border-radius: 36px;
    color: var(--white);
    cursor: pointer;
    padding: 12px 40px;
    outline: none;
    transition: all .5s ease;
    width: fit-content;

    &:disabled {
      cursor: not-allowed;
    }

    @media (max-width: 575px) {
      grid-column: span 2;
    }

    @media (max-width: 400px) {
      grid-column: initial;
    }
  }
`

export const ContactsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 80px;
  width: fit-content;
  width: max-content;

  @media (max-width: 764px) {
    flex-direction: column;
    margin: 0;
  }
`

export const ContactItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 32%;

  i {
    color: rgba(202, 200, 200, 1);
    fill: rgba(202, 200, 200, 1);
    transition: all .5s ease-in-out;

    &:hover {
      scale: 1.06;
    }
  }

  a {
    width: 100%;
  }

  button {
    background: linear-gradient(45deg, var(--primary), var(--secondary));
    border: 1px solid transparent;
    border-radius: 36px;
    color: var(--white);
    cursor: pointer;
    font-size: 12pt;
    padding: 8px 12px;
    outline: none;
    transition: all .5s ease;
    white-space: nowrap;
    width: 100%;
  }

  a, span {
    color: #888;
  }

  @media (max-width: 764px) {
    max-width: 200px;
    width: 100%;

    a, span, button {
      font-size: 10.5pt;
    }
  }
`
