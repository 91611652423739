import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 34px;
  display: flex;
  padding-top: 50px;
  position: relative;

  @media (max-width: 764px) {
    padding-top: 24px;
  }
`

export const IconContainer = styled.div`
  aspect-ratio: 1;
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  height: auto;
  max-width: 108px;
  position: absolute;
  padding: 10px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  @media (max-width: 764px) {
    height: 80px;
    max-width: 80px;

    img {
      height: 100%;
    }
  }
`

export const Content = styled.div`
  align-items: center;
  background-color: rgba(217, 217, 217, 0.39);
  border-radius: 34px;
  display: flex;
  min-height: 160px;
  flex-direction: column;
  justify-content: center;
  padding: 64px 12px 12px;
  width: 100%;

  p {
    color: var(--primary);
    font-size: 12pt;
    font-weight: 500;
    text-align: center;
    max-width: 95%;
    margin-top: 4px;
  }
`
