import styled from 'styled-components'
import Accordion from '../../../../components/Accordion'
import UT from '../../../../components/UnderlinedTitle'

export const BenefitsContainer = styled.div`
  align-items: start;
  display: flex;
  gap: 16px;
  padding-bottom: 32px;
  justify-content: center;
  max-width: 1200px;
  padding-inline: 28px;
  width: 100%;

  > figure {
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  @media (max-width: 764px) {
    flex-direction: column-reverse;
    padding-top: 32px;

    > figure img {
      max-width: 60%;
    }
  }
`

export const BenefitsContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`

export const BenefitsTitle = styled(UT)`
  color: var(--primary);
  font-weight: 800;
  padding-inline: 0;
  text-align: center;
  width: fit-content;

  &::before {
    width: 64%;
  }

  @media (max-width: 580px) {
    line-height: normal;
    white-space: normal;
  }

  @media (max-width: 400px) {
    font-size: 15pt;
  }
`

export const BenefitsAccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const BenefitAccordion = styled(Accordion)`
  .content {
    padding: 0 64px 32px 52px;

    h3 {
      color: var(--primary);
      font-size: 13.5pt;
      font-weight: 600;
    }

    p, ul li {
      color: var(--text-secondary);
      font-size: 13.5pt;
      line-height: 18pt;

      strong {
        color: var(--primary);
        font-weight: 700;
      }

      span {
        color: var(--primary);
        font-weight: 600;
      }
    }

    ul {
      list-style: disc;
      margin: 0 0 16px 24px;
      width: fit-content;

      li + li {
        margin-top: 8px;
      }
    }

    figure {
      width: fit-content;

      img {
        max-width: 100%;
      }
    }

    @media (max-width: 764px) {
      padding: 0 20px 32px;

      p, ul li {
        font-size: 12pt;
      }
    }

    @media (max-width: 400px) {
      p, ul li {
        max-width: initial;
      }
    }
  }

`
