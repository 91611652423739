import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;
  padding: 100px 28px 0;
  width: 100%;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;

  figure {
    max-width: fit-content;
    width: 100%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    figure {
      max-width: 64%;
    }
  }

  @media (max-width: 764px) {
    flex-direction: column-reverse;
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  h1 {
    font-size: 30pt;
    font-weight: 800;
    max-width: 700px;
  }

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    line-height: 18pt;
    max-width: 575px;
    width: 100%;

    strong {
      font-weight: 700;
    }
  }

  @media (max-width: 1420px) {
    h1 {
      font-size: 27pt;
    }
  }

  @media (max-width: 764px) {
    h1 {
      font-size: 21pt;
      text-align: center;
    }

    p {
      font-size: 12pt;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 18pt;
    }

    p {
      max-width: initial;
    }
  }
`
