import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import ProductsList from '../../../components/ProductsList'
import { LoadProductsByCartegory } from '../../../domain/use-case/LoadProductsByCartegory'
import * as S from './styled'

interface CategoryProps {
  categorySlug: string
  loadProductsByCartegory: LoadProductsByCartegory
}

export default function Category ({ loadProductsByCartegory, categorySlug }: CategoryProps): ReactElement {
  const { slug } = useParams() as { slug: string }

  return <S.Container>
    <S.Content>
      <ProductsList displayTitle slug={slug} loadProductsByCartegory={loadProductsByCartegory} />
    </S.Content>
  </S.Container>
}
