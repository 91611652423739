import styled from 'styled-components'

export const NavContainer = styled.nav`
  display: flex;
  height: 72px;

  ul {
    align-items: center;
    display: flex;
    gap: 32px;
    list-style: none;

    li {
      align-items: center;
      display: flex;
      height: 100%;
      position: relative;
      white-space: nowrap;
    }
  }

  @media (max-width: 1124px) {
    display: none;
  }
`
