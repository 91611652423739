import { ReactElement } from 'react'
import { PageState } from '../../../data/enums/PageState'
import useLoadPageBySlug from '../../../hooks/useLoadPageBySlug'
import Alveolar from './Alveolar'
import Applications from './Applications'
import Benefits from './Benefits'
import Differentials from './Differentials'
import Laminated from './Laminated'
import Personalized from './Personalized'
import * as S from './styled'

export default function Plates (): ReactElement {
  const { data, state } = useLoadPageBySlug<any>('solucoes-para-negocios/chapas')

  if (state === PageState.LOADING) return <>Carregando...</>
  if (state === PageState.NO_CONTENT) return <>Nenhum conteúdo disponivel</>

  return <S.Container>
    <Laminated data={data} />

    <Differentials data={data} />

    <Applications data={data} />

    <Personalized data={data} />

    <Alveolar data={data} />

    <Benefits data={data} />
  </S.Container>
}
