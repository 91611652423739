import styled from 'styled-components'

export const ApplicationsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1142px;
  padding: 32px 28px;
  width: 100%;
`

export const ApplicationsContent = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  gap: 32px 20px;
  place-items: center;

  @media (max-width: 955px) {
    grid-template-columns: 1fr;
  }
`

export const ApplicationItem = styled.div`
  align-items: center;
  border: 4px solid var(--primary);
  border-radius: 92px;
  display: flex;
  gap: 32px;
  justify-content: center;
  max-width: 306px;
  padding: 8px 16px;
  position: relative;
  width: 100%;

  &:last-child {
    grid-column: span 3;
    max-width: 708px;
    place-self: center;

    p {
      white-space: normal;
    }

    svg {
      right: -12px;
    }

    @media (max-width: 955px) {
      grid-column: initial;
      padding: 16px;

      svg {
        right: 0px;
      }
    }

    @media (max-width: 764px) {
      svg {
        right: 8px;
      }
    }

    @media (max-width: 400px) {
      svg {
        right: 16px;
      }
    }
  }

  p {
    color: #626060;
    font-size: 15pt;
    font-weight: 700;
    line-height: 21pt;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }

  svg {
    position: absolute;
    right: -24px;
    top: -24px;
  }

  @media (max-width: 764px) {
    p {
      font-size: 12pt;
    }

    svg {
      right: -12px;
      width: 36px;
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 10.5pt;
    }

    svg {
      width: 32px;
    }
  }
`
