import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  background-color: var(--white);
  flex-direction: column;
  width: 100%;
`

export const Content = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  gap: 32px;
  flex-direction: column;
  max-width: 1196px;
  padding: 80px 28px;
  width: 100%;
`
