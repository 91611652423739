import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CountUp from '../../components/CountUp'
import bannerBg from './assets/BANNER1.png'
import bannerBgTwo from './assets/BANNER2.png'
import paper from './assets/papelaria.png'
import business from './assets/solucoes.png'

export const HistoryBannerBg = styled.div`
  aspect-ratio: 1370 / 468;
  background-image: url(${bannerBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  @media (max-width: 1024px) {
    aspect-ratio: initial;
  }
`

export const HistoryBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  padding: 64px;
  height: 100%;
  max-width: 1366px;

  h1, p, a {
    color: var(--white);
  }

  h1 {
    font-size: 33pt;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    font-size: 22pt;
  }

  a {
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 40px;
    font-size: 12pt;
    padding: 8px 12px;
    width: fit-content;
  }

  @media (max-width: 764px) {
    padding: 28px 32px;

    h1 {
      font-size: 18pt;
    }

    p {
      font-size: 12pt;
    }

    a {
      font-size: 9pt;
      padding: 4px 8px;
    }
  }
`

export const VideoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;

  video {
    aspect-ratio: 1366 / 422;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
`

export const AboutSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  figure.slide-in-image {
    position: relative;
    width: 45%;

    img {
      left: -100%;
      position: relative;
      transition: left .8s linear;
      width: 100%;

      &.visible {
        left: 0;
      }
    }
  }

  @media (max-width: 764px) {
    align-items: initial;
    flex-direction: column;

    figure {
      width: 80%;
        &.slide-in-image {
          width: 80%;
          img {
          left: 0;
        }
      }
    }
  }
`

export const AboutContent = styled.div`
  width: 48%;

  div {
    width: 90%;

    h1 {
      font-size: 30pt;
      font-weight: 400;
      line-height: 30pt;
      margin-bottom: 24px;

      span {
        color: var(--text-title);
        font-weight: 700;
      }
    }

    p {
      font-size: 13.5pt;
      margin-bottom: 16px;

      br {
        content: "";
        display: block;
        margin: 18px;
      }
    }
  }

  @media (max-width: 764px) {
    padding-inline: 32px;
    width: initial;

    div {
      width: initial;

      h1 {
        font-size: 20pt;
        margin-bottom: 16px;
      }

      p {
        font-size: 12pt;
        margin-bottom: 8px;
      }
    }
  }
`

export const ReadMoreLink = styled(Link)`
  background-color: transparent;
  border: 1px solid var(--primary);
  border-radius: 40px;
  color: var(--text-title);
  font-size: 12pt;
  font-weight: 700;
  padding: 2px 12px;
  width: fit-content;

  @media (max-width: 764px) {
    font-size: 10pt;
    padding: 4px 8px;
  }
`

export const NewsReadMoreLink = styled.a`
  background-color: transparent;
  border: 1px solid var(--primary);
  border-radius: 40px;
  color: var(--text-title);
  font-size: 12pt;
  font-weight: 700;
  padding: 2px 12px;
  width: fit-content;

  @media (max-width: 764px) {
    font-size: 10pt;
    padding: 4px 8px;
  }
`

export const SolutionsSection = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 45% 1fr;
  gap: 16px;

  @media (max-width: 764px) {
    grid-template-columns: 1fr;
    padding-inline: 32px;
  }
`

export const SolutionsContent = styled.div`
  padding-left: 72px;

  h1 {
    font-size: 30pt;
    margin-bottom: 32px;
  }

  p {
    font-size: 13.5pt;
    margin-bottom: 24px;
  }

  @media (max-width: 764px) {
    padding: 0;

    h1 {
      font-size: 18pt;
      margin: 16px 0;
    }

    p {
      font-size: 12pt;
      margin-bottom: 16px;
    }
  }
`

export const SolutionImageCardsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const SolutionImageCardBg = styled.div`
  align-items: center;
  aspect-ratio: 1;
  background-image: url(${paper});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  max-height: 366px;
  max-width: 366px;
  width: 100%;

  &:last-of-type {
    background-image: url(${business});
  }
`

export const SolutionImageCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  height: 100%;
  position: relative;
  width: 100%;

  p {
    color: var(--text-title);
    font-size: 27pt;
    line-height: 30pt;
    font-weight: 900;
    margin-bottom: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  a {
    position: absolute;
    bottom: 28%;
  }

  @media (max-width: 1366px) {
    p {
      font-size: 22pt;
      line-height: 27pt;
    }

    a {
      font-size: 10pt;
    }
  }

  @media (max-width: 1194px) {
    p {
      font-size: 18pt;
      line-height: 24pt;
    }
  }

  @media (max-width: 1024px) {
    p {
      font-size: 14pt;
      line-height: 21pt;
    }

    a {
      font-size: 8pt;
    }
  }

  @media (max-width: 900px) {
    p {
      font-size: 12pt;
      line-height: 19pt;
    }
  }

  @media (max-width: 764px) {
    p {
      font-size: 10pt;
      line-height: 15pt;
    }

    a {
      padding: 2px 4px;
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 8pt;
      line-height: 12pt;
    }
  }
`

export const InstitutionalBannerBg = styled.div`
  aspect-ratio: 1370 / 472;
  background-image: url(${bannerBgTwo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  @media (max-width: 1024px) {
    aspect-ratio: initial;
  }
  `

export const InstitutionalBanner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  max-width: 1366px;
  margin: 0 auto;
  gap: 32px;
  padding: 28px;
  width: 100%;

  hr {
    height: 50%;
  }

  figure {
    width: 33%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 8px;

    hr {
      height: 0;
      width: 50%;
    }
  }
`

export const InstitutionalContent = styled.div`
  position: relative;
  white-space: nowrap;

  figure {
    max-width: 93px;
    position: absolute;
    right: 2%;

    @media (max-width: 1370px) {
      max-width: 82px;
    }

    @media (max-width: 1180px) {
      max-width: 68px;
    }
  }
`

export const FirstText = styled.p`
  color: var(--white);
  font-size: 30px;
  font-weight: 600;

  br {
    content: "";
    display: block;
    margin: 0px;
  }

  @media (max-width: 1370px) {
    font-size: 24px;
  }


  @media (max-width: 1180px) {
    font-size: 20px;
  }
`

export const Text = styled.p`
  color: var(--white);
  font-size: 35px;
  font-weight: 600;

  br {
    content: "";
    display: block;
    margin: 0px;
  }

  @media (max-width: 1370px) {
    font-size: 28px;
  }

  @media (max-width: 1180px) {
    font-size: 24px;
  }
`

export const MediumText = styled.span`
  color: var(--white);
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;

  br {
    content: "";
    display: block;
    margin: -24px;
  }

  @media (max-width: 440px) {
    font-size: 32px;
  }
`

export const LargeText = styled.span`
  color: var(--primary);
  font-size: 71px;
  font-weight: 800;
  text-transform: uppercase;

  br {
    content: "";
    display: block;
    margin: -20px;
  }


  @media (max-width: 1370px) {
    font-size: 60px;
  }

  @media (max-width: 1180px) {
    font-size: 48px;
  }
`

export const LargeNumber = styled(CountUp)`
  color: var(--primary);
  font-size: 120px;
  font-weight: 800;

  &.production {
    color: var(--white);
  }


  @media (max-width: 1370px) {
    font-size: 96px;
  }


  @media (max-width: 1180px) {
    font-size: 72px;
  }

  @media (max-width: 440px) {
    font-size: 56px;
    margin-top: 8px;
    display: inline-block;
  }
`

export const BottomText = styled.span`
  color: var(--white);
  font-size: 50px;
  font-weight: 600;

  &.production {
    font-size: 45px;
  }


  @media (max-width: 1370px) {
    font-size: 44px;

    &.production {
      font-size: 40px;
    }
  }


  @media (max-width: 1180px) {
    font-size: 36px;

    &.production {
      font-size: 36px;
    }
  }

  @media (max-width: 440px) {
    display: inline-block;
    font-size: 28px;
    margin-top: 8px;
  }
`

export const EnvironmentBannerBg = styled.div<{ bannerenvironment: string }>`
  aspect-ratio: 1370 / 450;
  background-image: url(${props => props.bannerenvironment});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`

export const EnvironmentBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
`

export const EnvironmentTopContent = styled.div`
  aspect-ratio: 964 / 210;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  padding-left: 2%;
  width: 70.5%;

  h2 {
    color: var(--black);
    font-size: 32pt;
    font-weight: 400;

    br {
      content: "";
      display: block;
      margin: -8px;
    }

    span {
      color: var(--black);
      font-size: 40pt;
    }
  }

  div {
    p {
      color: var(--black);
      font-size: 17pt;

      br {
        content: "";
        display: block;
        margin: -2px;
      }
    }
  }

  @media (max-width: 1366px) {
    h2 {
      font-size: 28pt;

      span {
        font-size: 36pt;
      }
    }

    div {
      p {
        font-size: 14pt;
      }
    }
  }

  @media (max-width: 1194px) {
    h2 {
      font-size: 24pt;

      span {
        font-size: 30pt;
      }
    }

    div {
      p {
        font-size: 12pt;
      }
    }
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 22pt;

      span {
        font-size: 28pt;
      }
    }

    div {
      p {
        font-size: 10pt;
      }
    }
  }

  @media (max-width: 900px) {
    h2 {
      font-size: 20pt;

      span {
        font-size: 26pt;
      }
    }

    div {
      p {
        font-size: 9pt;
      }
    }
  }

  @media (max-width: 764px) {
    h2 {
      font-size: 16pt;

      br {
        margin: -10px;
      }

      span {
        font-size: 22pt;
      }
    }

    div {
      p {
        font-size: 8pt;
      }
    }
  }

  @media (max-width: 650px) {
    h2 {
      font-size: 14pt;

      span {
        font-size: 18pt;
      }
    }

    div {
      p {
        font-size: 6pt;
      }
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 8pt;

      br {
        margin: -4px;
      }

      span {
        font-size: 10pt;
      }
    }

    div {
      p {
        font-size: 4pt;
      }
    }
  }
`

export const EnvironmentBottomContent = styled.div`
  align-self: flex-end;
  bottom: ${64 * 100 / 764}%;
  display: flex;
  flex-direction: column;
  height: 42%;
  gap: 8px;
  justify-content: center;
  left: ${804 * 100 / 1366}%;
  position: absolute;

  h2 {
    color: var(--white);
    font-size: 22pt;
  }

  p {
    color: var(--white);
    font-size: 20pt;
    margin-bottom: 8px;
  }

  @media (max-width: 1366px) {
    h2 {
      font-size: 18pt;
    }

    p {
      font-size: 16pt;
    }
  }

  @media (max-width: 1194px) {
    h2 {
      font-size: 16pt;
    }

    p {
      font-size: 14pt;
    }
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 14pt;
    }

    p {
      font-size: 12pt;
    }
  }

  @media (max-width: 900px) {
    h2 {
      font-size: 12pt;
    }

    p {
      font-size: 10pt;
    }
  }

  @media (max-width: 764px) {
    h2 {
      font-size: 10pt;
    }

    p {
      font-size: 8pt;
    }
  }

  @media (max-width: 650px) {
    h2 {
      font-size: 8pt;
    }

    p {
      font-size: 6pt;
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 6pt;
    }

    p {
      font-size: 4pt;
    }
  }
`

export const LearnMoreLink = styled(Link)`
  background-color: transparent;
  border: 1px solid var(--white);
  border-radius: 40px;
  color: var(--white);
  font-size: 12pt;
  font-weight: 700;
  padding: 2px 12px;
  width: fit-content;

  @media (max-width: 764px) {
    font-size: 6pt;
    padding: 0 6px;
  }
`

export const LatestNewsSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 40px 0 32px;
  width: 100%;

  h1 {
    border-bottom: 2px solid #B3B2B2;
    font-size: 30pt;
    line-height: 48px;
    padding-inline: 60px;
    position: relative;
    text-align: center;
    white-space: nowrap;

    &::before {
      background: var(--primary);
      background: linear-gradient(to bottom, var(--secondary) 0%, var(--primary) 100%);
      background: -webkit-linear-gradient(top, var(--secondary) 0%, var(--primary) 100%);
      bottom: -3px;
      content: "";
      height: 4px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 70%;
    }
  }

  @media (max-width: 764px) {
    h1 {
      font-size: 21pt;
      padding-inline: 40px;

      &::before {
        width: 76%;
      }
    }
  }
`

export const CarouselContainer = styled.div`
  max-width: 1020px;
  width: 100%;

  @media (max-width: 764px) {
    padding: 0 32px 32px;
  }
`

export const LatestNewsCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  place-content: center;
  padding-bottom: 40px;
  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

export const LatestNewCard = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 300px;
  width: 100%;

  p {
    font-size: 13.5pt;
    text-align: center;
  }

  figure {
    aspect-ratio: 1;
    border-radius: 32px;
    display: flex;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 764px) {
    p {
      font-size: 12pt;
    }
  }
`
