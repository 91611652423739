import styled from 'styled-components'
import { Container as DifferentialsContainer, Content as DifferentialsContent } from '../Differentials/styled'

export const Container = styled(DifferentialsContainer)`
  @media (max-width: 955px) {
    flex-direction: column-reverse;
  }
`

export const Content = styled(DifferentialsContent)`
  padding-inline: 32px 64px;

  h1 {
    max-width: 500px;
  }

  @media (max-width: 1120px) {
    ul li {
      margin-top: 4px;
    }
  }

  @media (max-width: 764px) {
    padding-inline: 0;
  }
`
