import { HeroModel } from '../entities/HeroModel'

export const mapToHero = (hero: any): HeroModel => {
  return {
    title: hero.titulo,
    subTitulo: hero.sub_titulo,
    imagemDeFundo: hero.imagem_de_fundo,
    botao: hero.botao
  }
}
