import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const NavLink = styled.a<{ $active: boolean }>`
  color: #888;
  font-size: 10.5pt;
  padding-bottom: 2px;
  text-decoration-line: ${({ $active }) => $active && 'underline'};
  text-decoration-thickness: 4px;
  text-underline-offset: 6px;
  width: fit-content;

  &:hover {
    text-decoration-line: underline;
  }

  @media (max-width: 1124px) {
    font-size: 15pt;
    text-underline-offset: 6px;
  }
`

export const NavInnerItemsContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  left: 0;
  position: absolute;
  top: 72%;
  width: 100%;
  z-index: 1;

  hr {
    width: 90%;
  }

  @media (max-width: 1124px) {
    display: none;
  }
`

export const NavInnerItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  min-width: fit-content;
  padding: 8px;
  width: 100%;

  p, > a {
    color: var(--text-primary);
    font-size: 10.5pt;
  }

  &:hover {
    p, > a {
      color: var(--primary);
    }
  }

`

export const NavInnerSubItemsContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(to right, transparent 8px, var(--white) 8px);
  display: flex;
  flex-direction: column;
  left: 100%;
  position: absolute;
  top: 0;
  width: fit-content;

  hr {
    margin-left: 8px;
    width: 80%;
  }

  @media (max-width: 1124px) {
    display: none;
  }
`

export const NavInnerSubItem = styled(Link)`
  align-items: center;
  color: var(--text-primary);
  font-size: 10.5pt;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 32px 8px 16px;
  width: 100%;
  z-index: 1;

  &:hover {
    color: var(--primary);
  }
`
