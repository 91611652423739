import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 70px;
  padding: 100px 28px;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 140px;
  max-width: 1140px;
`
