import { AxiosInstance } from 'axios'
import { LoadBlogPosts } from '../../domain/use-case/LoadBlogPosts'

export class ApiLoadBlogPosts implements LoadBlogPosts {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadBlogPosts.Params): Promise<LoadBlogPosts.Result> {
    const response = await this.api.get('/wp-json/plb2024/v1/blog')
    switch (response.status) {
      case 200:
        return { posts: response.data }
      case 204:
        throw new Error('Sem conteudo')
      default:
        throw new Error('Erro interno do servidor')
    }
  }
}
