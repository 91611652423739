import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 1280px;
  padding: 64px 28px;
  width: 100%;
`
