import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-around;
  max-width: 1280px;

  > figure {
    align-self: flex-start;
    max-width: 50%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    figure {
      max-width: 40%;
    }
  }

  @media (max-width: 764px) {
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;

    > figure {
      align-self: center;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 43%;

  @media (max-width: 764px) {
    max-width: 80%;
  }

  @media (max-width: 650px) {
    max-width: initial;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  h1 {
    font-size: 30pt;
    font-weight: 800;
  }

  h3 {
    color: var(--primary);
    font-size: 13.5pt;
    line-height: 18pt;
  }

  p {
    color: var(--text-secondary);
    font-size: 13.5pt;
    line-height: 18pt;

    strong {
      font-weight: 700;
    }
  }

  @media (max-width: 764px) {
    align-items: center;

    h1 {
      font-size: 24pt;
      max-width: initial;
      text-align: center;
    }

    h3, p {
      font-size: 12pt;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 21pt;
    }
  }
`
