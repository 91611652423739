import { Fragment, ReactElement, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import NavItemWithSubMenuProps from './props'
import * as S from './styled'

export default function MobileNavItem ({ label, to, onClick, submenuItems }: NavItemWithSubMenuProps): ReactElement {
  const { pathname } = useLocation()

  const activate = useCallback((path: string): boolean => pathname.includes(path), [pathname])

  return <li>
    <S.NavLink onClick={onClick} to={to} $active={activate(to)}>
      {label}
    </S.NavLink>

    {(submenuItems != null) && <S.NavInnerItemsContainer>
      {submenuItems.map((submenuItem, index) => <Fragment key={index}>
          <S.NavInnerItem $active={activate(submenuItem.to)}>
            {submenuItem.subItems != null
              ? <>
                <p>{submenuItem.label}</p>

                {<S.NavInnerSubItemsContainer>
                  {submenuItem.subItems.map((subItem, subIndex, subItems) => <Fragment key={subIndex}>
                    <S.NavInnerSubItem onClick={onClick} to={subItem.to} $active={activate(subItem.to)}>
                      {subItem.label}
                    </S.NavInnerSubItem>

                    {subIndex < (subItems.length - 1) && <hr />}
                  </Fragment>
                  )}
                </S.NavInnerSubItemsContainer>}
              </>
              : <Link onClick={onClick} to={submenuItem.to}>{submenuItem.label}</Link>
            }
          </S.NavInnerItem>

          {index < (submenuItems.length - 1) && <hr />}
        </Fragment>
      )}
    </S.NavInnerItemsContainer>}
  </li>
}
