import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import arrowBack from './assets/arrowBack.png'
import arrowForward from './assets/arrowForward.png'
import TabsProps from './props'
import * as S from './styled'

export default function Tabs ({ activeView, tabs, onClick }: TabsProps): ReactElement {
  const tabRefs = useRef<Array<HTMLButtonElement | null>>([])
  const tabsContainerRef = useRef<HTMLDivElement>(null)
  const [maxScroll, setMaxScroll] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  const scrollTabs = useCallback((direction: 'left' | 'right'): void => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current
      const scrollAmount = container.clientWidth / 2

      if (direction === 'left') {
        container.scrollLeft -= scrollAmount
      } else {
        container.scrollLeft += scrollAmount
      }

      setScrollPosition(container.scrollLeft)
    }
  }, [])

  const handleTabClick = useCallback((index: number): void => {
    if (tabRefs.current[index] && tabsContainerRef.current) {
      tabRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }

    onClick(index)
  }, [onClick])

  useEffect(() => {
    const container = tabsContainerRef.current

    if (container) {
      setMaxScroll(container.scrollWidth - container.clientWidth)

      const handleScroll = (): void => setScrollPosition(container.scrollLeft)

      container.addEventListener('scroll', handleScroll)
      return () => container.removeEventListener('scroll', handleScroll)
    }
  }, [tabsContainerRef.current?.scrollWidth])

  return <S.Container>
    <S.ArrowButton onClick={() => scrollTabs('left')} disabled={scrollPosition === 0}>
      <img src={arrowBack} alt="Voltar" />
    </S.ArrowButton>

    <S.TabsContainer ref={tabsContainerRef}>
      <S.TabButton
        $active={activeView === -1}
        onClick={() => handleTabClick(-1)}
        ref={(el) => { tabRefs.current[-1] = el }}
      >
        Todos os Produtos
      </S.TabButton>
      {tabs.map((tab, i) => <S.TabButton
        key={tab.id}
        $active={activeView === i}
        onClick={() => handleTabClick(i)}
        ref={(el) => { tabRefs.current[i] = el }}
      >
        {tab.name}
      </S.TabButton>)}
    </S.TabsContainer>

    <S.ArrowButton onClick={() => scrollTabs('right')} disabled={scrollPosition >= maxScroll}>
      <img src={arrowForward} alt="Avançar" />
    </S.ArrowButton>
  </S.Container>
}
